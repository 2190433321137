/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import dataAdapter from './adapter';
import { setSettingsCallsPaused, getAdvancedSettings, getGroupsList } from  './actions';

const meSlice = createSlice({
  name: 'globalGroupList',
  initialState: {
    status: 'loading',
    message: '',
    error: null,
    data: [],
    selectedGroup: null,
    settings: {},
  },
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
  },
  extraReducers: {
    [setSettingsCallsPaused.fulfilled]: (state) => {
      const callsPaused = !(state.settings.calls_paused == 1);

      state.settings.calls_paused = (callsPaused === true ? 1 : 0);
    },
    [getGroupsList.pending]: state => {
      state.status = 'loading';
    },
    [getGroupsList.fulfilled]: (state, action) => {
      const { status, message, data } = action.payload;

      state.status = status;
      state.message = message;
      state.data = dataAdapter(data);
    },
    [getGroupsList.rejected]: (state, { payload, error, status }) => {
      state.status = status;
      state.error = payload?.message ?? error.message;
    },
    [getAdvancedSettings.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.settings = data;
    },
  },
});

export const actions = {
  ...meSlice.actions,
  getGroupsList,
  getAdvancedSettings,
  setSettingsCallsPaused,
};

export default meSlice.reducer;

