import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from './formatters';

export const getLocations = createAsyncThunk('locations/getLocations', async (_, { rejectWithValue }) => {
  try {
    const { data: locations } = await api.get('/locations');

    return { locations: formatters.constructLocations(locations.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const createLocation = createAsyncThunk(
  'locations/createLocation',
  async (location = {}, { rejectWithValue, dispatch }) => {
    const { label: name } = location;

    try {
      const { data } = await api.post('/locations', {
        name,
      });

      dispatch(getLocations());

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getLocation = createAsyncThunk('locations/getLocation', async (locationId, { rejectWithValue }) => {
  try {
    const { data: location } = await api.get(`/locations/${locationId}`);

    return { location: formatters.constructLocation(location.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateLocation = createAsyncThunk(
  'locations/updateLocation',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { id, label: name } = payload;

    try {
      const { data } = await api.put(`/locations/${id}`, { name });

      dispatch(getLocations());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const removeLocation = createAsyncThunk(
  'locations/removeLocation',
  async (locationId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.delete(`/locations/${locationId}`);

      dispatch(getLocations());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
