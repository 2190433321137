import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getRepLabels, createRepLabel, removeRepLabel, updateRepLabel } from './actions';

const repLabelsAdapter = createEntityAdapter({
  selectId: repLabel => repLabel.id,
});
const repLabelsInit = repLabelsAdapter.getInitialState();

const repLabelsSlice = createSlice({
  name: 'repLabels',
  initialState: {
    list: repLabelsInit,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get rep labels
    [getRepLabels.pending]: state => {
      state.loading = true;
    },
    [getRepLabels.fulfilled]: (state, action) => {
      state.loading = false;
      repLabelsAdapter.setAll(state.list, action.payload.repLabels);
    },
    [getRepLabels.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // create rep label
    [createRepLabel.pending]: state => {
      state.loading = true;
    },
    [createRepLabel.fulfilled]: state => {
      state.loading = false;
    },
    [createRepLabel.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // remove rep label
    [removeRepLabel.pending]: state => {
      state.loading = true;
    },
    [removeRepLabel.fulfilled]: state => {
      state.loading = false;
    },
    [removeRepLabel.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update rep label
    [updateRepLabel.pending]: state => {
      state.loading = true;
    },
    [updateRepLabel.fulfilled]: state => {
      state.loading = false;
    },
    [updateRepLabel.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default repLabelsSlice.reducer;

export const repLabelsSelectors = repLabelsAdapter.getSelectors();

export const actions = {
  ...repLabelsSlice.actions,
  getRepLabels,
  createRepLabel,
  removeRepLabel,
  updateRepLabel,
};
