/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from 'src/features/advanceSettings/state/formatters';

export const getConfiguration = createAsyncThunk('advanced/getConfiguration', async (_, { rejectWithValue }) => {
  try {
    const { data: configuration } = await api.get('/settings/advanced');

    return {
      configuration: formatters.constructConfiguration(configuration.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateConfiguration = createAsyncThunk(
  'advanced/updateConfiguration',
  async (configuration, { rejectWithValue, dispatch }) => {
    const request = JSON.parse(JSON.stringify(configuration));

    request.ivr_announce = JSON.parse(JSON.stringify(configuration.ivr_announce))
      .filter((inv) => (inv.enabled == true))
      .map((inv, index) => (
        inv.id
      ));

    request.email_reports = JSON.parse(JSON.stringify(configuration.email_reports))
      .map((email_report, index) => ({
        email: email_report.email,
        daily: email_report.daily === true ? 1 : 0,
        weekly: email_report.weekly === true ? 1 : 0,
        monthly: email_report.monthly === true ? 1 : 0,
      }));

    request.enable_call_recording = JSON.parse(JSON.stringify(configuration.enable_call_recording)) == true ? 1 : 0;

    try {
      const { data } = await api.post('/settings/advanced', { ...request });

      dispatch(getConfiguration());

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const searchConfiguration = createAsyncThunk(
  'advanced/searchConfiguration',
  async (search, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.get('/inquiries', {
        params: {
          page: 1,
          search,
        },
      });

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePhoneData = createAsyncThunk(
  'advanced/deletePhoneData',
  async ({ id, search }, { rejectWithValue, dispatch }) => {
    try {
      await api.post(`/inquiries/remove-personal-data/${id}`, {});

      dispatch(searchConfiguration(search));

      return true;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
