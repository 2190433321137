import axios from 'axios';

import { getAccessToken } from '../api/_common';

const instance = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}/${process.env.GATSBY_API_ROOT}`,
  headers: { "Content-Type": "multipart/form-data" },
});

instance.interceptors.request.use(
  config => {
    const token = getAccessToken();

    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    if (response.data.status === 'error') {
      return Promise.reject(response.data);
    }

    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default instance;
