/* eslint-disable no-shadow */
import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import api from 'src/services/axios';

import * as formatters from 'src/features/overview/state/formatters';

import { OVERVIEW_STATUSES } from './types';
import { convertDateTimeLocalForStats } from 'src/helpers/time';
import { addDays } from 'date-fns';

export const getOverviewData = createAsyncThunk(
  'overview/getOverviewData',
  async (_, { rejectWithValue, getState }) => {
    const { overview } = getState();
    const { date, groupId, statuses } = overview.filters;

    const filters = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
      // 'status[]': Object.keys(statuses)
      //   .filter(statusKey => Boolean(statuses[statusKey]))
      //   .map(statusKey => OVERVIEW_STATUSES[statusKey]),
    };

    try {
      const data = await api.get('/stat/overview-download', {
        params: { ...filters },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      });

      return {
        // htmlTable: data?.data,
        downloadedFile: data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getOverviewLeadData = createAsyncThunk(
  'overview/getOverviewLeadData',
  async (_, { rejectWithValue, getState }) => {
    const { overview } = getState();
    const { date, groupId } = overview.filtersLead;

    const filters = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
      // 'status[]': Object.keys(statuses)
      //   .filter(statusKey => Boolean(statuses[statusKey]))
      //   .map(statusKey => OVERVIEW_STATUSES[statusKey]),
    };

    try {
      const data = await api.get('/stat/overview-lead-download', {
        params: { ...filters },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      });

      return {
        // htmlTable: data,
        downloadedFile: data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getPerformance = createAsyncThunk('overview/getPerformance', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: performance } = await api.get('/stat/overview-performance', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      performance: formatters.constructPerformance(performance.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getSources = createAsyncThunk('overview/getSources', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: sources } = await api.get('/stat/overview-source-of-leads', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      sources: formatters.constructSources(sources.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getResults = createAsyncThunk('overview/getResults', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: results } = await api.get('/stat/overview-lead-results', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      results: formatters.constructResults(results.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getCallActivities = createAsyncThunk(
  'overview/getCallActivities',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: callActivities } = await api.get('/stat/overview-call-activity', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return {
        callActivities: formatters.constructCallActivities(callActivities.data),
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getUpcomingCalls = createAsyncThunk(
  'overview/getUpcomingCalls',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: upcomingCalls } = await api.get('/stat/overview-upcoming-calls', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return {
        upcomingCalls: formatters.constructUpcomingCalls(upcomingCalls.data),
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getAvgSpeed = createAsyncThunk('overview/getAvgSpeed', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: avgSpeed } = await api.get('/stat/avg-speed-to-lead', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      avg: formatters.constructAvgSpeed(avgSpeed.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getResponse = createAsyncThunk('overview/getResponse', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: response } = await api.get('/stat/overview-lead-response-status', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      response: formatters.constructResponse(response.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getParkedCalls = createAsyncThunk('overview/getParkedCalls', async (payload = {}, { rejectWithValue }) => {
  const { groupId } = payload;

  const params = {
    group_id: groupId,
  };

  try {
    const { data: parked } = await api.get('/parked-calls', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      parked: formatters.constructParkedCalls(parked.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const removeParkedCall = createAsyncThunk(
  'overview/removeParkedCall',
  async (callId, { rejectWithValue, getState, dispatch }) => {
    const {
      overview: { groupId },
    } = getState();

    try {
      const { data } = await api.delete(`/parked-calls/${callId}`);

      dispatch(getParkedCalls({ groupId }));

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getParkedCallsData = createAsyncThunk(
  'overview/getParkedCallsData',
  async (_, { rejectWithValue, getState }) => {
    const {
      overview: { groupId },
    } = getState();

    const params = {
      group_id: groupId,
    };

    try {
      const { data } = await api.get('/parked-calls/download', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getActiveCalls = createAsyncThunk('overview/getActiveCalls', async (payload = {}, { rejectWithValue }) => {
  const { groupId } = payload;

  const params = {
    group_id: groupId,
  };

  try {
    const { data: active } = await api.get('/active-calls', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      active: formatters.constructActiveCalls(active.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
