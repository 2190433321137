/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { constructLeadCallVolume } from 'src/features/insights/state/formatters';
import { exportToCSV } from 'src/helpers/exportToCSV';
import { getCallAttempt, getCallDuration, getCallResults, getGroupData, getGroupReps } from './actions';

const downloadFiltersInitState = {
  statuses: {
    '86d': false,
    connected: false,
    notConnected: false,
    noAnswer: false,
    taggedNo: false,
    taggedYes: false,
  },
  allStatuses: false,
  reps: [],
  allReps: true,
  date: null,
  groupId: null,
  isLoading: false,
  error: null,
  isExpanded: false,
};

const filtersInitState = {
  date: null,
  groupId: null,
  isLoading: false,
  isExpanded: true,
};

const resultsInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
  tableDataSets: [],
};

const durationInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  labels: [],
  dataSets: [],
};

const attemptInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  data: [],
};

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    downloadFilters: downloadFiltersInitState,
    filters: filtersInitState,
    results: resultsInitState,
    duration: durationInitState,
    attempt: attemptInitState,
    date: { from: new Date().toISOString(), to: new Date().toISOString() },
    groupId: null,
    loading: false,
    error: null,
  },
  reducers: {
    setGroup: (state, { payload }) => {
      state.filters.groupId = payload;
      state.downloadFilters.groupId = payload;
    },
    toggleFilterPanel: state => {
      state.filters.isExpanded = !state.filters.isExpanded;
    },
    toggleDownloadFilterPanel: state => {
      state.downloadFilters.isExpanded = !state.downloadFilters.isExpanded;
    },
    toggleStatuses: state => {
      state.downloadFilters.allStatuses = !state.downloadFilters.allStatuses;
      state.downloadFilters.statuses = Object.keys(state.downloadFilters.statuses).reduce(
        (acc, key) => ({
          ...acc,
          [key]: state.downloadFilters.allStatuses
        }),
        {},);
    },
    toggleStatus: (state, { payload }) => {
      state.downloadFilters.statuses = {
        ...state.downloadFilters.statuses,
        [payload]: !state.downloadFilters.statuses[payload]
      };

      const isNonCheckedExists = !!Object.keys(state.downloadFilters.statuses).find((key) => {
        return state.downloadFilters.statuses[key] === false;
      });

      state.downloadFilters.allStatuses = !isNonCheckedExists;
    },
    setFilterDate: (state, { payload }) => {
      state.filters.date = payload;
      state.date = payload;
    },
    setDownloadFilterDate: (state, { payload }) => {
      state.downloadFilters.date = payload;
      // state.date = payload;
    },
    setFilterGroup: (state, { payload }) => {
      state.downloadFilters.groupId = payload;
    },
    toggleReps: state => {
      state.downloadFilters.allReps = !state.downloadFilters.allReps;
      state.downloadFilters.reps = state.downloadFilters.reps.map(rep => ({
        ...rep,
        isActive: state.downloadFilters.allReps
      }));
    },
    toggleRep: (state, { payload }) => {
      const itemIndex = state.downloadFilters.reps.findIndex(({ id }) => id === payload);

      state.downloadFilters.reps = [
        ...state.downloadFilters.reps.slice(0, itemIndex),
        { ...state.downloadFilters.reps[itemIndex], isActive: !state.downloadFilters.reps[itemIndex].isActive },
        ...state.downloadFilters.reps.slice(itemIndex + 1),
      ];

      const isNonCheckedExists = !!state.downloadFilters.reps.find((rep) => {
        return rep.isActive === false;
      });

      state.downloadFilters.allReps = !isNonCheckedExists;
    },
    toggleCallResultsPanel: state => {
      state.results.isExpanded = !state.results.isExpanded;
    },
    toggleCallDurationPanel: state => {
      state.duration.isExpanded = !state.duration.isExpanded;
    },
    toggleCallAttemptPanel: state => {
      state.attempt.isExpanded = !state.attempt.isExpanded;
    },
  },
  extraReducers: {
    // get group reps
    [getGroupReps.pending]: state => {
      state.downloadFilters.isLoading = true;
    },
    [getGroupReps.fulfilled]: (state, action) => {
      state.downloadFilters.isLoading = false;
      state.downloadFilters.reps = action.payload.reps;
      state.downloadFilters.allReps = true;
    },
    [getGroupReps.rejected]: (state, { payload, error }) => {
      state.downloadFilters.isLoading = false;
      state.downloadFilters.error = payload?.message ?? error.message;
    },
    // get group download
    [getGroupData.pending]: state => {
      state.downloadFilters.isLoading = true;
    },
    [getGroupData.fulfilled]: (state, action) => {
      state.downloadFilters.isLoading = false;

      exportToCSV(action.payload);
    },
    [getGroupData.rejected]: (state, { payload, error }) => {
      state.downloadFilters.isLoading = false;
      state.downloadFilters.error = payload?.message ?? error.message;
    },
    // get call results
    [getCallResults.pending]: state => {
      state.results.isLoading = true;
    },
    [getCallResults.fulfilled]: (state, action) => {
      state.results.isLoading = false;
      state.results = { ...state.results, ...action.payload.results };
    },
    [getCallResults.rejected]: (state, { payload, error }) => {
      state.results.isLoading = false;
      state.results.error = payload?.message ?? error.message;
    },
    // get call duration
    [getCallDuration.pending]: state => {
      state.duration.isLoading = true;
    },
    [getCallDuration.fulfilled]: (state, action) => {
      state.duration.isLoading = false;
      state.duration = {
        ...state.duration,
        ...action.payload.duration
      };
    },
    [getCallDuration.rejected]: (state, { payload, error }) => {
      state.duration.isLoading = false;
      state.duration.error = payload?.message ?? error.message;
    },
    // get call attempt
    [getCallAttempt.pending]: state => {
      state.attempt.isLoading = true;
    },
    [getCallAttempt.fulfilled]: (state, action) => {
      state.attempt.isLoading = false;
      state.attempt = action.payload.data;
    },
    [getCallAttempt.rejected]: (state, { payload, error }) => {
      state.attempt.isLoading = false;
      state.attempt.error = payload?.message ?? error.message;
    },
  },
});

export default groupSlice.reducer;

export const actions = {
  ...groupSlice.actions,
  getGroupReps,
  getGroupData,
  getCallResults,
  getCallDuration,
  getCallAttempt,
};
