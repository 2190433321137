import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { getMe, getLoading } from 'src/features/users/state/selectors';

export default function Logo(props) {
  const user = useSelector(getMe);
  const isLoading = useSelector(getLoading);
  const [isFirstLoading, setIsFirstLoading] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (isLoading === false) {
      setIsFirstLoading(true);
    }
  }, [isLoading]);

  return <LoadingContainer height="auto" loading={isLoading && isFirstLoading === false}>
    {
      user?.customer?.logo
        ? <img src={user.customer.logo} alt="White label Logo" width="100%" />
        : <Img fluid={data.placeholderImage.childImageSharp.fluid} {...props} />
    }
  </LoadingContainer>;
}
