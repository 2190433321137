/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';

export const steps = ['Get Started', 'Onboarding Options', 'Connect Leads', 'Test Leads'];

const isStepSkipped = (step, skipped) => {
  return Boolean(skipped.filter(ele => ele === step).length);
};
const StepperSlice = createSlice({
  name: 'onboarding',
  initialState: {
    steps,
    activeStep: 1,
    skipped: [],
    configuration: {
      zapier: false,
      email: false,
      http: false,
      salesforce: false,
      ms: false,
      native: false,
    },
  },
  reducers: {
    nextStep: state => {
      if (isStepSkipped(state.activeStep, state.skipped)) {
        state.skipped.pop();
      }
      navigate(`../${state.activeStep + 1}/`);
    },
    previousStep: state => {
      navigate(`../${state.activeStep - 1}/`);
    },
    skipStep: state => {
      state.skipped.push(state.activeStep);
      navigate('/me/overview/');
    },
    currentStep: (state, action) => {
      state.activeStep = action.payload.activeStep;
    },
    setConfiguration: (state, action) => {
      state.configuration[action.payload.config] = true;
    },
  },
});

export const { nextStep, previousStep, skipStep, currentStep, setConfiguration } = StepperSlice.actions;

export default StepperSlice.reducer;
