import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const StyledMainListItem = withStyles(theme => ({
  root: {
    height: 43,
    borderRadius: 5,
    '& .MuiListItemText-root': {
      color: theme.palette.secondary.main,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected .MuiListItemText-root > *': {
      color: '#ffffff',
      fontWeight: 400,
    },
    '&.Mui-selected .MuiListItemIcon-root > *': {
      color: '#ffffff'
    },
  },
}))(ListItem);
const StyledMainListItemIcon = withStyles(() => ({}))(ListItemIcon);
const StyledMainListItemText = withStyles(() => ({
  primary: {
    fontSize: 14,
  },
}))(ListItemText);

const MainMenuItem = ({ children, selected, onClick, button }) => {
  return (
    <StyledMainListItem selected={!!selected} button={button} onClick={onClick}>
      <StyledMainListItemIcon>{children[0]}</StyledMainListItemIcon>
      <StyledMainListItemText>{children[1]}</StyledMainListItemText>
    </StyledMainListItem>
  );
};

const StyledSubListItem = withStyles(theme => ({
  root: {
    height: 39,
    width: 180,
    marginLeft: 40,
    borderRadius: 5,
    '& .MuiListItemText-root': {
      color: 'black',
    },
    '& .MuiListItemText-root > .MuiListItemText-primary': {
      fontSize: 14,
    },
    '& .MuiListItemIcon-root': {
      fill: 'black',
      stroke: 'black',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected .MuiListItemText-root > *': {
      color: '#ffffff',
      fontWeight: 400,
    },
    '&.Mui-selected .MuiListItemIcon-root > *': {
      color: '#ffffff',
    },
  },
}))(ListItem);
const StyledSubListItemIcon = withStyles(() => ({}))(ListItemIcon);
const StyledSubListItemText = withStyles(() => ({
  primary: {
    fontSize: 12,
  },
}))(ListItemText);

const SubMenuItem = ({ children, selected, onClick }) => {
  return (
    <StyledSubListItem selected={!!selected} button onClick={onClick}>
      <StyledSubListItemText>{children}</StyledSubListItemText>
    </StyledSubListItem>
  );
};

export { MainMenuItem, SubMenuItem };

MainMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  button: PropTypes.bool,
};

SubMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

MainMenuItem.defaultProps = {
  selected: false,
  button: true,
};

SubMenuItem.defaultProps = {
  selected: false,
};
