import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from 'src/features/users/state/formatters';

export const getMe = createAsyncThunk('users/getMe', async (_, { rejectWithValue }) => {
  try {
    const { data: me } = await api.get('/me');

    return {
      me: formatters.constructMe(me.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const createUser = createAsyncThunk('users/createUser', async (user, { rejectWithValue, dispatch }) => {
  const { lastName, firstName, email, phone, password, confirmPassword, role } = user;

  const newUser = {
    username: firstName,
    name: `${firstName} ${lastName}`,
    email,
    phone,
    password,
    password_confirm: confirmPassword,
    user_role: role,
  };

  try {
    const { data: user } = await api.post('/users', newUser);

    dispatch(getUsers());
    dispatch(getInvitedUsers());

    return {
      user: formatters.constructUser(user.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const inviteUser = createAsyncThunk('users/inviteUser', async (user = {}, { rejectWithValue, dispatch }) => {
  const { email, role } = user;

  const newUser = {
    email,
    role,
  };

  try {
    const { data: invitedUser } = await api.post('/invites', newUser);

    dispatch(getUsers());
    dispatch(getInvitedUsers());

    return {
      invitedUser: formatters.constructInvitedUser(invitedUser.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getUsers = createAsyncThunk('users/getUsers', async (payload = {}, { rejectWithValue }) => {
  try {
    const { data: users } = await api.get('/users', {
      params: { ...payload },
    });


    const tempUsers = {
      users: formatters.constructUsers(users.data),
    };

    return tempUsers

  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getInvitedUsers = createAsyncThunk('users/getInvitedUsers', async (payload = {}, { rejectWithValue }) => {
  try {
    const { data: users } = await api.get('/invites', {
      params: { ...payload },
    });

    return {
      users: formatters.constructInvitedUsers(users.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getUser = createAsyncThunk('users/getUser', async (payload = {}, { rejectWithValue }) => {
  const { id } = payload;

  try {
    const { data: user } = await api.get(`/users/${id}`);

    return {
      user: formatters.constructUserById(user.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getInvitedUser = createAsyncThunk('users/getInvitedUser', async (payload = {}, { rejectWithValue }) => {
  const { id } = payload;

  try {
    const { data: invitedUser } = await api.get(`/invites/${id}`);

    return {
      user: formatters.constructInvitedUserById(invitedUser.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const removeUser = createAsyncThunk('users/removeUser', async (userId, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.delete(`/users/${userId}`);

    dispatch(getUsers());
    dispatch(getInvitedUsers());

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const removeInvitedUser = createAsyncThunk(
  'users/removeInvitedUser',
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.delete(`/invites/${userId}`);

      dispatch(getUsers());
      dispatch(getInvitedUsers());

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUser = createAsyncThunk('users/updateUser', async (payload = {}, { rejectWithValue, dispatch }) => {
  const { id, name, username, email, phone, role, password, confirmPassword } = payload;

  try {
    const { data } = await api.put(`/users/${id}`, {
      name,
      username,
      email,
      phone,
      user_role: role,
      password: password,
      password_confirm: confirmPassword,
    });

    dispatch(getMe());
    dispatch(getUsers());
    dispatch(getInvitedUsers());

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateInvitedUser = createAsyncThunk(
  'users/updateInvitedUser',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { id, email, role } = payload;

    try {
      const { data } = await api.put(`/invites/${id}`, {
        email,
        role,
      });

      dispatch(getMe());
      dispatch(getUsers());
      dispatch(getInvitedUsers());

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAvatar = createAsyncThunk('users/updateAvatar', async (avatar, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.post('/user_avatar', {
      avatar_base64: avatar,
    });

    dispatch(getMe());

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const deleteAvatar = createAsyncThunk('users/deleteAvatar', async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.delete('/user_avatar');

    dispatch(getMe());

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const resendInvitation = createAsyncThunk('users/resendInvitation', async (userId, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`/resend-invitation-email/${userId}`);

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getBillingPortal = createAsyncThunk('billing/getBillingPortal', async (_, { rejectWithValue }) => {
  try {
    const { data: configuration } = await api.get('/settings/billingPortal');

    return {
      configuration: formatters.constructBillingPortal(configuration.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
