import React from 'react';
import { makeStyles, Drawer } from '@material-ui/core';

import NavigationMenus from './navigationMenus';
import Logo from 'src/components/logo';

const useStyles = makeStyles(theme => ({
  drawer: ({ drawerWidth }) => ({
    width: drawerWidth,
    flexShrink: 0,
  }),
  drawerPaper: ({ drawerWidth }) => ({
    width: drawerWidth,
    padding: theme.spacing(0, 3),
  }),
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 155,
    padding: theme.spacing(5, 0),
  },
  toolbar: theme.mixins.toolbar,
  menusWrapper: {
    padding: theme.spacing(3, 0),
  },
}));

export default function LeftDrawer({ data, drawerWidth }) {
  const classes = useStyles({ drawerWidth });

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.logoWrapper}>
        <Logo />
      </div>

      <div className={classes.menusWrapper}>
        <NavigationMenus data={data} />
      </div>
    </Drawer>
  );
}
