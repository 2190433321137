/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Button } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';

import { nextStep, previousStep, skipStep } from './slice';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    padding: theme.spacing(4, 10),
    justifyContent: 'space-between',
  },
  nextButton: {
    marginLeft: 'auto',
    height: 53,
    width: 143,
  },
  skipButton: {
    margin: 'auto',
    color: theme.palette.primary.main,
    height: 53,
    width: 143,
  },
  backButton: {
    marginRight: 'auto',
    height: 53,
    width: 143,
  },
}));

const isStepOptional = (steps, step) => {
  return steps[step - 1].optional;
};

const Footer = props => {
  const { activeStep, steps, stepsInfo } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {activeStep !== 1 && (
        <Button
          className={classes.backButton}
          startIcon={<ArrowBack style={{ marginRight: 14 }} />}
          onClick={() => {
            props.previousStep();
          }}
        >
          Back
        </Button>
      )}

      {isStepOptional(steps, activeStep) && (
        <Button
          className={classes.skipButton}
          onClick={() => {
            props.skipStep();
          }}
        >
          Skip
        </Button>
      )}

      {activeStep === steps.length ? (
        <Button
          disableElevation
          className={classes.nextButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            // dispatch({ type: 'finish' });
          }}
        >
          Finish
        </Button>
      ) : (
        <Button
          disableElevation
          className={classes.nextButton}
          disabled={!stepsInfo[activeStep].isValidToSubmit}
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward style={{ marginLeft: 14 }} />}
          onClick={() => {
            props.nextStep();
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = ({ onboarding }) => {
  return { ...onboarding };
};

const mapDispatchToProps = { nextStep, previousStep, skipStep };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
