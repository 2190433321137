/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useMediaQuery, ThemeProvider, CssBaseline } from '@material-ui/core';

import theme from 'src/themes/onboardingTheme';
import useAuth from 'src/hooks/useAuth';

import DesktopLayout from './desktopLayout';
import MobileLayout from './mobileLayout';

export default ({ children }) => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const isAuth = useAuth();

  useEffect(() => {
    if (isAuth === true) {
      navigate('/me/overview/');
    }
  }, [isAuth]);

  return (
    isAuth === false && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {matches ? <DesktopLayout>{children}</DesktopLayout> : <MobileLayout>{children}</MobileLayout>}
      </ThemeProvider>
    )
  );
};
