import { getCurrentUser } from 'src/api/_common';
import { USER_ROLES } from 'src/api/userRoles';

const checkRole = (role) => {
  const currentUser = getCurrentUser();

  if (!currentUser || !role) {
    return false;
  }

  if (Array.isArray(role)) {
    for (let item of role) {
      if ((currentUser.user_role === item.toString()) || (currentUser.user_role === USER_ROLES[item.toLowerCase()])) {
        return true;
      }
    }

    return false;
  }

  return (currentUser.user_role === role.toString()) || (currentUser.user_role === USER_ROLES[role.toLowerCase()]);
};

export default checkRole;