/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, IconButton, ButtonBase, makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';

const useStyles = makeStyles(() => ({
  userInfo: {
    padding: '0 10px',
  }
}));

export default function GroupsList() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    navigate('/me/settings/users');
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.userInfo}>
      <ButtonBase onClick={handleClick}>
        <Avatar />
      </ButtonBase>

      {/* <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClose} id="Group 1">
          test
        </MenuItem>
      </Menu> */}
    </div>
  );
}
