/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';

export const getTimezones = createAsyncThunk('timezones/getTimezones', async (_, { rejectWithValue }) => {
  try {
    const result = await api.get('/time-zones');

    const { data } = result.data;

    return { timezones: data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
