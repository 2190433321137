import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { makeStyles, BottomNavigation, BottomNavigationAction } from '@material-ui/core';

import pages from './pages';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 70,
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
  },
  bottomNavigationAction: {
    padding: theme.spacing(2, 0),
    minWidth: 'unset',
  },
  selected: {
    borderTop: '4px solid',
    borderColor: theme.palette.primary.main,
  },
}));

export default function MobileBottomNavigation() {
  const classes = useStyles();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    navigate(`/me${newValue}`);
  };

  return (
    <BottomNavigation value={pathname.split('/me')[1]} onChange={handleChange} className={classes.root}>
      {pages.map(page => (
        <BottomNavigationAction
          key={page.link || page.name}
          value={page.link || (page.subPages && page.subPages.length > 0 && page.subPages[0]?.link)}
          icon={page.icon}
          showLabel={false}
          classes={{ root: classes.bottomNavigationAction, selected: classes.selected }}
        />
      ))}
    </BottomNavigation>
  );
}
