/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


import { getCallRecordings, deleteCallRecording } from './actions';

const Slice = createSlice({
  name: 'callRecordings',
  initialState: {
    isTabExpended: true,
    list: [],
    page: 0,
    totalCount: 0,
    isLoading: false,
    error: null,
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: {
    // get group reps
    [getCallRecordings.pending]: state => {
      state.isLoading = true;
    },
    [getCallRecordings.fulfilled]: (state, action) => {
      const { items, total } = action.payload.data;

      state.isLoading = false;
      state.list = items;
      state.totalCount = total * 1;
    },
    [getCallRecordings.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default Slice.reducer;

export const { setTabExpend, setPage } = Slice.actions;

export const actions = {
  ...Slice.actions,
  getCallRecordings,
  deleteCallRecording,
};


