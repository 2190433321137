import { createSlice } from '@reduxjs/toolkit';

import { getConfiguration } from './actions';

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    configuration: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get integrations configuration
    [getConfiguration.pending]: state => {
      state.loading = true;
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      state.configuration = action.payload.configuration;
    },
    [getConfiguration.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default integrationsSlice.reducer;

export const actions = {
  ...integrationsSlice.actions,
  getConfiguration,
};
