/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/api/me';
import dataAdapter from './adapter';

export const getGroupsList = createAsyncThunk('me/groups/get', async () => api.getGroupsList());

const Slice = createSlice({
  name: 'me',
  initialState: {
    status: 'loading',
    message: '',
    error: null,
    data: [],
    selectedGroup: false,
  },
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
  },
  extraReducers: {
    [getGroupsList.pending]: state => {
      state.status = 'loading';
    },
    [getGroupsList.fulfilled]: (state, action) => {
      const { status, message, data } = action.payload;
      state.status = status;
      state.message = message;
      state.data = dataAdapter(data);
    },
    [getGroupsList.rejected]: (state, { payload, error, status }) => {
      state.status = status;
      state.error = payload?.message ?? error.message;
    },
  },
});

export const { setSelectedGroup } = Slice.actions;

export default Slice.reducer;
