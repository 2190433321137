/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
import { parse, differenceInDays } from 'date-fns';

import { getNumberWithOrdinal } from 'src/helpers/ordinalSuffix';
import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time';

export const constructPerformance = data => {
  if (!data) return null;

  const { by_date: byDate, total } = data;

  const dataKeys = [
    { key: 'leadsPosted', originalKey: 'leads_posted', dataKey: 'posted', name: 'Leads Posted' },
    { key: 'leadsCalled', originalKey: 'leads_called', dataKey: 'called', name: 'Leads Called' },
    { key: 'callsConnected', originalKey: 'calls_connected', dataKey: 'connected', name: 'Calls Connected' },
    // { key: 'callsTagged', originalKey: 'calls_tagged', dataKey: 'tagged', name: 'Tagged Call' },
  ];

  let labels = [];

  let sets = {
    posted: [],
    called: [],
    connected: [],
    // tagged: [],
  };

  Object.keys(byDate).forEach(dateKey => {
    const {
      date,
      leads_posted: leadsPosted,
      leads_called: leadsCalled,
      calls_connected: callsConnected,
      // calls_tagged: callsTagged,
    } = byDate[dateKey];

    labels.push(date);
    sets.posted.push(+leadsPosted);
    sets.called.push(+leadsCalled);
    sets.connected.push(+callsConnected);
    // sets.tagged.push(+callsTagged);
  });

  const dataSets = dataKeys.map(({ dataKey, name }) => ({
    label: name,
    data: sets[dataKey],
    borderWidth: 0,
  }));

  const legends = dataKeys.map(({ name }) => name);

  const totalSum = total['leads_posted'];// dataKeys.reduce((acc, { originalKey }) => acc + +total[originalKey], 0);
  const totalSet = dataKeys.reduce(
    (acc, { key, originalKey, name }) => ({
      ...acc,
      [key]: {
        name,
        value: +total[originalKey],
        percent: ((+total[originalKey] * 100) / totalSum).toFixed(0),
      },
    }),
    {},
  );

  return {
    legends,
    labels,
    dataSets,
    totalSet,
  };
};

export const constructSources = data => {
  if (!data) return [];

  return data.map(({ source, connected, tagged }) => ({
    source,
    connected: parseFloat(connected.toFixed(0)),
    // tagged: parseFloat(tagged.toFixed(2)),
  }));
};

export const constructResults = data => {
  if (!data) return [];

  const dataKeys = [
    { dataKey: 'total', name: 'Total Leads' },
    { dataKey: 'connected', name: 'Connected' },
    // { dataKey: 'tagged', name: 'Tagged' },
  ];

  let labels = [];

  let sets = {
    total: [],
    connected: [],
    // tagged: [],
  };

  const legends = dataKeys.map(({ name }) => name);

  data.forEach(({ attempt, ...datasets }) => {
    labels.push(`${getNumberWithOrdinal(attempt)} Attempt`);

    dataKeys.forEach(({ dataKey }) => {
      sets[dataKey].push(+datasets[dataKey]);
    });
  });

  const dataSets = dataKeys.map(({ dataKey, name }) => ({
    label: name,
    data: sets[dataKey],
    borderWidth: 0,
  }));

  return {
    legends,
    labels,
    dataSets,
  };
};

export const constructCallActivities = data => {
  if (!data) return [];

  const legends = [];

  const labels = [];

  const dataSets = [];

  const dataSetsByLegends = [];

  const dataKeys = [
    { dataKey: '1', name: '1st Attempt' },
    { dataKey: '2', name: '2nd Attempt' },
    { dataKey: '3', name: '3rd Attempt' },
    { dataKey: '4', name: '4rd Attempt' },
    { dataKey: '5', name: '5rd Attempt' },
    { dataKey: '6', name: '6rd Attempt' },
  ];

  dataKeys.forEach(({ dataKey }) => {
    Object.keys(data).forEach(key => {
      if (data[key][dataKey]) {
        legends[dataKey * 1 - 1] = `${getNumberWithOrdinal(dataKey)} Attempt`;
      }
    });
  });


  Object.keys(data).forEach(date => {
    labels.push(date);

    legends.forEach((value, key) => {
      if (!dataSetsByLegends[key]) {
        dataSetsByLegends[key] = [];
      }

      if (!data[date][`${key + 1}`]) {
        dataSetsByLegends[key].push(0);
      } else {
        dataSetsByLegends[key].push(data[date][`${key + 1}`] * 1);
      }
    });
  });

  legends.forEach((value, key) => {
    dataSets[key] = {
      label: value,
      data: dataSetsByLegends[key],
      borderWidth: 0,
    };

  });

  return {
    legends,
    labels,
    dataSets: JSON.parse(JSON.stringify(dataSets)),
  };
};

export const constructUpcomingCalls = data => {
  if (!data) return {};

  const legendKeys = [
    { legendKey: 'prev7days', name: 'Prev 7 days' },
    { legendKey: 'next7days', name: 'Next 7 days' },
  ];
  const dataKeys = [
    { dataKey: 'new', name: 'Fresh Leads' },
    { dataKey: 'reattempts', name: 'Reattempts' },
    { dataKey: 'after_hours', name: 'After Hour Leads' },
  ];

  const labelsSet = new Set();
  const dataSets = [];
  const initialRowDataItem = { new: 0, reattempts: 0, after_hours: 0, total: 0 };
  const tableSets = {
    columns: [],
    data: [{ rowName: '', rowData: initialRowDataItem }],
  };

  tableSets.columns = [...dataKeys, { dataKey: 'total', name: 'Total' }];
  tableSets.data = legendKeys.map(({ legendKey, name }) => ({
    rowName: name,
    rowData: data[legendKey].reduce(
      (accItem, itemData) => ({
        ...accItem,

        ...dataKeys.reduce(
          (acc, { dataKey }) => ({
            ...acc,

            [dataKey]: Math.round((accItem[dataKey] ?? 0) + +itemData[dataKey]),
            total: Math.round((acc.total ?? 0) + (accItem[dataKey] ?? 0) + +itemData[dataKey]),
          }),
          {},
        ),
      }),
      { ...initialRowDataItem },
    ),
  }));

  Object.keys(data).forEach(item => {
    const label = legendKeys.find(({ legendKey }) => item === legendKey).name;
    const dataSet = [];

    data[item].forEach(({ week_day, ...itemData }) => {
      labelsSet.add(week_day);

      const itemTotal = dataKeys.reduce((acc, { dataKey }) => acc + +itemData[dataKey], 0);
      dataSet.push(itemTotal);
    });

    dataSets.push({
      label,
      data: dataSet,
      fill: false,
    });
  });

  const legends = legendKeys.reduce((acc, { legendKey, name }) => [...acc, ...(data[legendKey] ? [name] : [])], []);
  const labels = [...labelsSet];

  return {
    legends,
    labels,
    dataSets,
    tableSets,
  };
};

export const constructAvgSpeed = data => {
  if (!data) return {};

  const speedValues = Object.values(data.dates);
  const minSpeed = Math.min(...speedValues);
  const maxSpeed = Math.max(...speedValues);
  const min = isFinite(minSpeed) ? minSpeed : 0;
  const max = isFinite(maxSpeed) ? maxSpeed : 0;
  const secondaryDatasetsNeedleValue = data.interval_avg;

  const secondaryDatasets = [
    {
      label: 'Leads posted',
      data: [min, max],
      needleValue: secondaryDatasetsNeedleValue,
      backgroundColor: ['#325A73', '#6BB9B6'],
    },
  ];

  const dataSets = [
    {
      label: '',
      data: Object.entries(data.dates).map(([key, value]) => ({
        x: `${parse(key, 'yyyy-MM-dd', new Date())}`,
        y: +value,
      })),
      fill: false,
    },
  ];

  return {
    secondaryDatasets,
    secondaryDatasetsNeedleValue,
    dataSets,
  };
};

export const constructResponse = data => {
  if (!data) return {};

  const dataKeys = [
    { dataKey: 'press2', name: 'Called', backgroundColor: '#325A73' },
    { dataKey: 'connected', name: 'Connected', backgroundColor: '#F2A629' },
    { dataKey: 'no_press2', name: 'Not Called', backgroundColor: '#92B6C4' },
    // { dataKey: 'tagged', name: 'Tagged', backgroundColor: '#BF7A8D' },
  ];

  const legendsSet = new Set();
  const colorsSet = new Set();
  let secondaryTotal = {};
  let primaryData = {};

  Object.keys(data).forEach(itemKey => {
    dataKeys.forEach(({ dataKey, name, backgroundColor }) => {
      const itemValue = data[itemKey][dataKey];

      secondaryTotal = { ...secondaryTotal, [dataKey]: (secondaryTotal[dataKey] ?? 0) + +itemValue };
      primaryData = {
        ...primaryData,
        [dataKey]: {
          label: name,
          data: [
            ...(primaryData[dataKey]?.data ?? []),
            { x: `${parse(itemKey, 'yyyy-MM-dd', new Date())}`, y: itemValue },
          ],
        },
      };

      if (itemValue !== null && itemValue !== undefined) {
        legendsSet.add(name);
        colorsSet.add(backgroundColor);
      }
    });
  });


  const secondaryData = Object.values(secondaryTotal).map(secondaryItem =>
    Math.round(secondaryItem / Object.values(data).length),
  );

  const legends = [...legendsSet];
  const colors = [...colorsSet];
  const secondaryDatasets = [
    {
      data: secondaryData,
      backgroundColor: colors,
    },
  ];
  const primaryDatasets = dataKeys.map(({ dataKey }) => ({
    label: primaryData[dataKey]?.label,
    data: primaryData[dataKey]?.data.map(dataItem => ({
      ...dataItem,
      y: Math.round(dataItem.y),
    })),
  }));

  return {
    legends,
    secondaryDatasets,
    primaryDatasets,
  };
};

export const constructParkedCalls = data => {
  if (!data) return {};

  const dataKeys = [
    { dataKey: 'id', name: 'Id', hidded: true },
    { dataKey: 'name', name: 'Lead Name', cellStyle: { fontWeight: 700 } },
    { dataKey: 'phone', name: 'Lead Number' },
    { dataKey: 'routing', name: 'Routing Rule' },
    { dataKey: 'afterhours', name: 'After Hour Lead', width: 200 },
    { dataKey: 'attempt', name: 'Attempt Number', width: 200 },
  ];

  const newItems = data.items.map(({ nextcall, ...item }) => ({
    ...item,
    nextcall: nextcall ? convertDateTimeUTCtoLocalFormat(nextcall) : 'No data',
  }));

  const columns = dataKeys.map(({ dataKey, name, ...dataProps }) => ({ title: name, field: dataKey, ...dataProps }));
  const dataSets = newItems;

  return {
    columns,
    dataSets,
  };
};

export const constructActiveCalls = data => {
  if (!data) return {};

  const newItems = data.items.map(({ initiated, ...item }) => ({
    ...item,
    initiated: initiated ? convertDateTimeUTCtoLocalFormat(initiated, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }) : 'No data',
  }));
  return newItems;
};
