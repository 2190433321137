/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'callAttempt',
  initialState: {
    isTabExpended: true,
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
  },
});

export const { setTabExpend } = Slice.actions;
export default Slice.reducer;
