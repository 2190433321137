export const exportToExcel = (htmlTable, filename = 'Overview') => {
  let htmls = '';
  const uri = 'data:application/vnd.ms-excel;base64,';
  const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
  const base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  const format = function (s, c) {
    return s.replace(/{(\w+)}/g, function (m, p) {
      return c[p];
    });
  };

  htmls = htmlTable;

  const ctx = {
    worksheet: 'Worksheet',
    table: htmls,
  };

  const link = document.createElement('a');
  link.download = `${filename}.xls`;
  link.target = '_blank';
  link.href = uri + base64(format(template, ctx));
  link.click();
};
