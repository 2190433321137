import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import queryString from 'query-string';

import theme from 'src/themes/homeTheme';

import useAuth from 'src/hooks/useAuth';
import { removeAccessToken, removeCurrentUser } from 'src/api/_common'

export default ({ children }) => {
  const isAuth = useAuth();
  const isBrowser = typeof window !== "undefined";

  const [code, setCode] = useState(null);

  useEffect(() => {
    setCode(queryString.parse(window.location.search) && queryString.parse(window.location.search).code ? queryString.parse(window.location.search).code : null)
  }, [isBrowser])

  useEffect(() => {
    if (code && isAuth) {
      removeAccessToken();
      removeCurrentUser();
      window.dataLayer = [];
      navigate('/me/overview/?code=' + code);
      return;
    }
    if (isAuth === true) {
      navigate('/me/overview/');
    }
  }, [isAuth, code]);

  return (
    isAuth === false && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    )
  );
};
