/* eslint-disable camelcase */
import { createAxiosObject } from './_common';
import parkedCalls from './parkedCalls';
import activeCalls from './activeCalls';

const getSourceOfLeads = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/stat/overview-source-of-leads`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

const getLeadsResults = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/stat/overview-lead-results`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

const getCallActivity = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/stat/overview-call-activity`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

const getUpcomingCalls = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/stat/overview-upcoming-calls`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

const getPerformance = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/stat/overview-performance`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

const getAvgSpeedToLead = async ({ groupId: group_id, from, to }) => {
  const axios = createAxiosObject();
  const url = `/avg-speed-to-lead/:time`;
  const { data } = await axios.get(url, { params: { group_id, from, to } });
  return data;
};

export default {
  getCallActivity,
  getSourceOfLeads,
  getLeadsResults,
  getUpcomingCalls,
  getPerformance,
  getAvgSpeedToLead,
  ...parkedCalls,
  ...activeCalls,
};
