import React from 'react';

import GaugeIcon from 'src/assets/icons/gauge.svg';
import InsightIcon from 'src/assets/icons/insight.svg';
import GroupIcon from 'src/assets/icons/group.svg';
import SettingsIcon from 'src/assets/icons/setting.svg';
import RecordIcon from 'src/assets/icons/record.svg';
import SquareIcon from 'src/assets/icons/square.svg';
import HelpIcon from 'src/assets/icons/help.svg';
import ParkedIcon from 'src/assets/icons/parked.svg';
import ActveCallsIcon from 'src/assets/icons/activeCalls.svg';
// import SupportIcon from 'src/assets/icons/support.svg';

const pages = [
  {
    name: 'Dashboard',
    link: '/overview/',
    icon: <GaugeIcon />,
  },
  {
    name: 'Insights',
    link: '/insights/',
    icon: <InsightIcon />,
  },
  {
    name: 'Rep Performance',
    link: '/group-performance/',
    icon: <GroupIcon />,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    subPages: [
      { name: 'Integrations', link: '/settings/integrations/' },
      { name: 'Routing Rules', link: '/settings/routing-rules?page=rep' },
      { name: 'Advanced Settings', link: '/settings/advance-settings/', allowedFor: ['admin', 'manager'] },
      { name: 'Users', link: '/settings/users/' },
    ],
  },
  {
    name: 'Call Recordings',
    link: '/call-recordings/',
    icon: <RecordIcon />,
  },
  {
    name: 'Parked Calls',
    link: '/parked-calls/',
    icon: <ParkedIcon />,
  },
  {
    name: 'Active Calls',
    link: '/active-calls/',
    icon: <ActveCallsIcon />,
  },
  {
    name: 'Help and Support',
    icon: <HelpIcon />,
    class: true,
    popup: true,
    popupList: [
      {
        name: '619-770-1993',
        link: { url: 'tel:+16197701993', target: '_self' },
        onClickMethod: 'href',
        type: 1,
      },
      {
        name: 'Chat',
        link: 'openHelpChat',
        onClickMethod: 'function',
        type: 2,
      },
      {
        name: 'Search',
        link: { url: 'https://leverly.com/knowledge-base', target: '_blank' },
        onClickMethod: 'href',
        type: 3,
      },
      {
        name: 'Meet',
        link: { url: 'https://calendly.com/leverlyhelp/meeting', target: '_blank' },
        onClickMethod: 'href',
        type: 4,
      },
    ]
  },
];

export default pages;
