/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Button, Typography, Link } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import { nextStep, skipStep } from './slice';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    // justifyContent: 'space-between',
  },
  nextButton: {
    margin: theme.spacing(2, 0),
    height: 53,
  },
  skipButton: {
    // margin: 'auto',
    color: theme.palette.primary.main,
  },
  link: {
    cursor: 'pointer',
  },
}));

const isStepOptional = (steps, step) => {
  return steps[step - 1].optional;
};

const FooterMobile = props => {
  const { activeStep, steps } = props;
  const classes = useStyles();

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').childNodes[0].childNodes;
    if (elements.length !== 1) {
      return;
    }

    elements[0].childNodes[0].childNodes[0].click();
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" align="center" noWrap gutterBottom>
        Having troubles? <Link className={classes.link} onClick={openHelpChat}>Get help</Link>
      </Typography>
      {activeStep === steps.length ? (
        <Button
          disableElevation
          className={classes.nextButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            // dispatch({ type: 'finish' });
          }}
        >
          Finish
        </Button>
      ) : (
        <Button
          disableElevation
          className={classes.nextButton}
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward style={{ marginLeft: 14 }} />}
          onClick={() => {
            props.nextStep();
          }}
        >
          Next
        </Button>
      )}
      {isStepOptional(steps, activeStep) && (
        <Button
          className={classes.skipButton}
          onClick={() => {
            props.skipStep();
          }}
        >
          Skip
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = ({ onboarding }) => {
  return { ...onboarding };
};

const mapDispatchToProps = { nextStep, skipStep };

export default connect(mapStateToProps, mapDispatchToProps)(FooterMobile);
