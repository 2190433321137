// import 'chartjs-plugin-colorschemes';

import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { defaults, Chart } from 'react-chartjs-2';

import roundedCornerBarChart from 'src/helpers/chartJsHelpers';

import defaultTheme from './defaultTheme';

defaults.global.defaultFontFamily = 'Lato';
defaults.global.defaultFontSize = 14;
defaults.global.defaultFontColor = 'rgba(32, 34, 57, 0.7)';
defaults.global.defaultFontStyle = 'normal';

defaults.global.elements.line.tension = 0;
defaults.global.elements.line.borderWidth = 2;

defaults.global.elements.point.radius = 4;
defaults.global.elements.point.backgroundColor = 'rgba(255, 255, 255, 1)';
defaults.global.elements.point.borderWidth = 2;
defaults.global.elements.point.hoverBorderWidth = 2;
defaults.global.elements.point.hoverRadius = 5;
Chart.defaults.global.plugins.labels = false;

// Chart.elements.Rectangle.prototype.draw = roundedCornerBarChart;
// defaults.global.plugins.colorschemes.scheme = ['#325A73', '#F2A629', '#92B6C4', '#BF7A8D', '#56BC91', '#F17649'];

const theme = createTheme(defaultTheme, {
  typography: {
    button: {
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.4
    },
    overline: {
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: 1,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      lineHeight: 1.4,
      fontSize: 18,
      fontWeight: 700,
    },
    h5: {
      lineHeight: 1.4,
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      lineHeight: 1.3,
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      lineHeight: 1.3,
      fontSize: 28,
      fontWeight: 700,
    },
    h2: {
      lineHeight: 1.3,
      fontWeight: 700,
      fontSize: 36,
    },
    h1: {
      lineHeight: 1.2,
      fontWeight: 700,
      fontSize: 45,
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiButton: {
      root: {
        // height: 44,
        minWidth: 112,
      },
      containedSizeLarge: {
        fontSize: 16,
        padding: '12px 32px'
      },
      startIcon: {
        marginLeft: 0,
      }
      // contained: {
      //   '&:hover': {
      //     opacity: 0.5,
      //   },
      // },
    },
    MuiSelect: {
      selectMenu: {
        height: 20,
      },
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiInputBase: {
      root: {
        minHeight: 41,
        background: '#fff',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#2a97b7',
      },
      iconButton: {
        backgroundColor: 'transparent',
      },
    },
    MuiPickersDay: {
      day: {
        margin: 0,
        '& .MuiTypography-root': {
          fontWeight: 400,
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 42,
      },
    },
    MuiAutocomplete: {
      option: {
        height: 42,
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: 4
      }
    }
  },
});

export default responsiveFontSizes(theme);
