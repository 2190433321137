import React, { useEffect } from 'react';
import { makeStyles, AppBar, Toolbar, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Logo from 'src/components/logo';
import Image from 'src/components/stepsImage';
import GroupsList from './groupsList';
import NavigationMenus from './navigationMenus';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    zIndex: 1000,
  },
  appBar: {
    padding: theme.spacing(3, 0),
  },
  logoWrapper: {
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  logo: {
    width: 163,
  },
  imageWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    maxWidth: 375,
    // maxHeight: 220,
  },
  menusWrapper: {
    padding: theme.spacing(0, 3),
  },
}));

export default function MobileMenu({ onClose }) {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="transparent" className={classes.appBar} elevation={0}>
        <Toolbar>
          <div className={classes.logoWrapper}>
            <Logo className={classes.logo} />
          </div>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
        <Toolbar>
          <GroupsList />
        </Toolbar>
      </AppBar>
      <div className={classes.menusWrapper}>
        <NavigationMenus handleClose={onClose} />
      </div>
      <div className={classes.imageWrapper}>
        <Image step="step1" />
      </div>
    </div>
  );
}
