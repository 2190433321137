import 'react-perfect-scrollbar/dist/css/styles.css';
import 'simplebar-react/dist/simplebar.min.css';

import { createTheme, responsiveFontSizes } from '@material-ui/core';

import Sharp from 'src/assets/fonts';
import Lato from 'src/assets/fonts';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a97b7',
    },
    secondary: {
      main: '#202239',
    },
    background: {
      default: '#f6f6f6',
    },
    text: {
      primary: '#202239',
    },
  },
  typography: {
    fontFamily: 'Lato',
    // fontFamily: 'Lato, Lato',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': Lato,
      },
    },
    MuiBackdrop: {
      root: {
        // zIndex: 10000,
        // backgroundColor: '#f6f6f6',
      },
    },
  },
});

export default theme;
