import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';

import Header from 'src/features/me/mobileHeader';
import MobileBottomNavigation from 'src/features/me/mobileBottomNavigation';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, '70px'),
  },
}));

export default function MobileLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Container className={classes.root}>{children}</Container>
      <MobileBottomNavigation />
    </>
  );
}
