import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getLocations, createLocation, removeLocation, updateLocation } from './actions';

const locationsAdapter = createEntityAdapter({
  selectId: location => location.id,
});
const locationsInit = locationsAdapter.getInitialState();

const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    list: locationsInit,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get locations
    [getLocations.pending]: state => {
      state.loading = true;
    },
    [getLocations.fulfilled]: (state, action) => {
      state.loading = false;
      locationsAdapter.setAll(state.list, action.payload.locations);
    },
    [getLocations.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // create location
    [createLocation.pending]: state => {
      state.loading = true;
    },
    [createLocation.fulfilled]: state => {
      state.loading = false;
    },
    [createLocation.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // remove location
    [removeLocation.pending]: state => {
      state.loading = true;
    },
    [removeLocation.fulfilled]: state => {
      state.loading = false;
    },
    [removeLocation.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update location
    [updateLocation.pending]: state => {
      state.loading = true;
    },
    [updateLocation.fulfilled]: state => {
      state.loading = false;
    },
    [updateLocation.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default locationsSlice.reducer;

export const locationsSelectors = locationsAdapter.getSelectors();

export const actions = {
  ...locationsSlice.actions,
  getLocations,
  createLocation,
  removeLocation,
  updateLocation,
};
