/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';
import {
  getFirstAttemptPerformance,
  getConnectionsByAttempt,
  getNoConnections,
  getLeadCallVolume,
  getPressTwoConnectionsRate,
  getNewLeadFlow,
  getHighestConnections,
} from './actions';

const firstInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  labels: [],
  dataSets: [],
};

const connectionsInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  labels: [],
  dataSets: [],
};

const disconnectedInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  dataSets: [],
};

const volumesInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  dataSets: [],
  connectionsDataSets: [],
  pressTwoDataSets: [],
};

const flowInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  labels: [],
  dataSets: [],
};

const highestInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
};

const insightsSlice = createSlice({
  name: 'insights',
  initialState: {
    first: firstInitState,
    connections: connectionsInitState,
    disconnected: disconnectedInitState,
    volumes: volumesInitState,
    flow: flowInitState,
    highest: highestInitState,
    date: { from: new Date().toISOString(), to: new Date().toISOString() },
    // date: { from: startOfDay(new Date()), to: new Date() },
    groupId: null,
    loading: false,
    error: null,
  },
  reducers: {
    setReportingDate: (state, { payload }) => {
      state.date = payload;
    },
    toggleFirstAttemptPerformancePanel: state => {
      state.first.isExpanded = !state.first.isExpanded;
    },
    toggleConnectionsByAttemptPanel: state => {
      state.connections.isExpanded = !state.connections.isExpanded;
    },
    toggleNoConnectionsPanel: state => {
      state.disconnected.isExpanded = !state.disconnected.isExpanded;
    },
    toggleLeadCallVolumePanel: state => {
      state.volumes.isExpanded = !state.volumes.isExpanded;
    },
    toggleNewLeadFlowPanel: state => {
      state.flow.isExpanded = !state.flow.isExpanded;
    },
    toggleHighestConnectionsPanel: state => {
      state.highest.isExpanded = !state.highest.isExpanded;
    },
  },
  extraReducers: {
    // get first attempt performance
    [getFirstAttemptPerformance.pending]: state => {
      state.first.isLoading = true;
    },
    [getFirstAttemptPerformance.fulfilled]: (state, action) => {
      state.first.isLoading = false;
      state.first = { ...state.first, ...action.payload.first };
    },
    [getFirstAttemptPerformance.rejected]: (state, { payload, error }) => {
      state.first.isLoading = false;
      state.first.error = payload?.message ?? error.message;
    },
    // get connection by attempt
    [getConnectionsByAttempt.pending]: state => {
      state.connections.isLoading = true;
    },
    [getConnectionsByAttempt.fulfilled]: (state, action) => {
      state.connections.isLoading = false;
      state.connections = { ...state.connections, ...action.payload.connections };
    },
    [getConnectionsByAttempt.rejected]: (state, { payload, error }) => {
      state.connections.isLoading = false;
      state.connections.error = payload?.message ?? error.message;
    },
    // get no connections
    [getNoConnections.pending]: state => {
      state.disconnected.isLoading = true;
    },
    [getNoConnections.fulfilled]: (state, action) => {
      state.disconnected.isLoading = false;
      state.disconnected = { ...state.disconnected, ...action.payload.disconnected };
    },
    [getNoConnections.rejected]: (state, { payload, error }) => {
      state.disconnected.isLoading = false;
      state.disconnected.error = payload?.message ?? error.message;
    },
    // get lead call volume
    [getLeadCallVolume.pending]: state => {
      state.volumes.isLoading = true;
    },
    [getLeadCallVolume.fulfilled]: (state, action) => {
      state.volumes.isLoading = false;
      state.volumes = { ...state.volumes, ...action.payload.volumes };
    },
    [getLeadCallVolume.rejected]: (state, { payload, error }) => {
      state.volumes.isLoading = false;
      state.volumes.error = payload?.message ?? error.message;
    },
    // get press2 and connections rate
    [getPressTwoConnectionsRate.pending]: state => {
      state.volumes.isLoading = true;
    },
    [getPressTwoConnectionsRate.fulfilled]: (state, action) => {
      state.volumes.isLoading = false;
      state.volumes = { ...state.volumes, ...action.payload.volumes };
    },
    [getPressTwoConnectionsRate.rejected]: (state, { payload, error }) => {
      state.volumes.isLoading = false;
      state.volumes.error = payload?.message ?? error.message;
    },
    // get new lead flow
    [getNewLeadFlow.pending]: state => {
      state.flow.isLoading = true;
    },
    [getNewLeadFlow.fulfilled]: (state, action) => {
      state.flow.isLoading = false;
      state.flow = { ...state.flow, ...action.payload.flow };
    },
    [getNewLeadFlow.rejected]: (state, { payload, error }) => {
      state.flow.isLoading = false;
      state.flow.error = payload?.message ?? error.message;
    },
    // get highest connections
    [getHighestConnections.pending]: state => {
      state.highest.isLoading = true;
    },
    [getHighestConnections.fulfilled]: (state, action) => {
      state.highest.isLoading = false;
      state.highest = { ...state.highest, ...action.payload.highest };
    },
    [getHighestConnections.rejected]: (state, { payload, error }) => {
      state.flow.isLoading = false;
      state.highest.error = payload?.message ?? error.message;
    },
  },
});

export default insightsSlice.reducer;

export const actions = {
  ...insightsSlice.actions,
  getFirstAttemptPerformance,
  getConnectionsByAttempt,
  getNoConnections,
  getLeadCallVolume,
  getPressTwoConnectionsRate,
  getNewLeadFlow,
  getHighestConnections,
};
