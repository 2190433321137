import { USER_ROLES } from 'src/api/userRoles';

// export const USER_ROLES = {
//   admin: '0',
//   manager: '1',
//   viewer: '2',
// };
export { USER_ROLES };

export const USER_STATUSES = {
  invited: '0',
  created: '1',
};
