import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      // backgroundColor: '#fff',
      borderRadius: 6,
      minHeight: 150,
      [theme.breakpoints.up('sm')]: {
        minHeight: ({ height }) => height,
      },
    },
    noData: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      fontSize: 18,
      fontWeight: 700,
    },
  }),
);

const LoadingContainer = ({ height, type, children, classNames, noData, loading, initLoadingOnly }) => {
  const classes = useStyles({ height });
  // const [init, setInit] = useState(false);

  // useEffect(() => {
  //   if (loading && !init) {
  //     setInit(true);
  //   }
  // }, [loading, init]);

  return (
    <div className={clsx(classes.root, classNames)}>
      {(loading || (loading && initLoadingOnly && !init)) && <LoadingOverlay type={type} />}
      {noData && !loading && <span className={classes.noData}>No Data</span>}
      {children}
    </div>
  );
};

export default LoadingContainer;
