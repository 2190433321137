import { createTheme, responsiveFontSizes } from '@material-ui/core';

import defaultTheme from './defaultTheme';

const theme = createTheme(defaultTheme, {
  typography: {
    h1: {
      fontSize: 33,
      fontWeight: 700,
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      marginBottom: 24,
      color: '#2e3341',
    },

    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      color: '#8b8c97',
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 700,
      color: '#8b8c97',
    },
    button: {
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
      // lineHeight:1.2
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '@media (max-width: 375px)': {
          fontSize: 14,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 10,
      },
    },
    MuiLink: {
      root: {
        fontWeight: 700,
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: 'transparent',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#2a97b7',
      },
      iconButton: {
        backgroundColor: 'transparent',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          height: 0,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
