/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';

import ArrowDownIcon from 'src/assets/icons/arrowDown.svg';

import { useGroupListData, useFetchGroupList } from '../../state/hooks';

export const HeaderGroupsPicker = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { data, status, message, selectedGroup, setSelectedGroup } = useGroupListData();

  useFetchGroupList();

  const getGroupName = () => {
    if (!selectedGroup) {
      return 'All Groups';
    }
    return data.filter(({ id }) => id === selectedGroup)[0].name;
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    setSelectedGroup(null);
    handleClose();
  };

  const handleSelectGroup = id => {
    setSelectedGroup(id);
    handleClose();
  };

  return (
    <>
      <Button aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDownIcon color="primary" />}>
        {getGroupName()}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleSelectAll}>All Groups</MenuItem>
        {data.map(({ id, name }) => (
          <MenuItem
            key={id}
            onClick={() => {
              handleSelectGroup(id);
            }}
            id={id}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};