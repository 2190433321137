/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import overviewAPI from 'src/api/overview';

export const getPerformance = createAsyncThunk('performance/get', async arg => {
  return overviewAPI.getPerformance(arg);
});

const Slice = createSlice({
  name: 'Performance',
  initialState: {
    isTabExpended: true,
    message: '',
    data: [],
    arg: false,
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
  },
  extraReducers: {
    [getPerformance.pending]: (state, action) => {
      state.status = 'loading';
      state.arg = action.meta.arg;
    },
    [getPerformance.fulfilled]: (state, action) => {
      const { status, message, data } = action.payload;
      state.status = status;
      state.message = message;
      state.data = data;
    },
    [getPerformance.rejected]: () => {},
  },
});

export const { setTabExpend } = Slice.actions;
export default Slice.reducer;
