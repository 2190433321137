/* eslint-disable camelcase */
import qs from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as formatters from './formatters';

import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';
import api from 'src/services/axios';

export const getCallRecordings = createAsyncThunk(
  'callRecordings/getCallRecordings',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { page, groupId, from, to, rep, orderBy, orderDirection } = payload;

    const params = {
      page: page + 1,
      group_id: groupId,
      date_from: from,
      date_to: to,
      contact_info_id: rep,
      per_page: 10,
    };

    if (orderBy && orderDirection) {
      params[`sort[${orderBy}]`] = orderDirection.toUpperCase();
    }

    try {
      const { data } = await api.get('/calls/recordings', {
        params,
        paramsSerializer: paramsObj => qs.stringify(paramsObj),
      });

      const newData = { data: { items: [], total: data.data.total } };

      newData['data']['items'] = data.data.items.map((item) => {
        return {
          ...item,
          callScore: formatters.constructCallScoreData(item),
          callDetails: formatters.constructCallDetailData(item),
          leadPhone: phoneFormatter(item.leadPhone),
          repPhoneNumber: phoneFormatter(item.repPhoneNumber),
          callStartTime: convertDateTimeUTCtoLocalFormat(item.callStartTime),
          twilioRecordingUrl: (item.twilioRecordingUrl.indexOf('.mp3') > 0) ? item.twilioRecordingUrl.substring(0, item.twilioRecordingUrl.lastIndexOf('.')) : item.twilioRecordingUrl,
        }
      });

      return newData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  });

export const deleteCallRecording = createAsyncThunk(
  'callRecordings/deleteCallRecording',
  async (payload = {}, { rejectWithValue, dispatch, getState }) => {
    const { id } = payload;

    const stateParams = getState();

    const groupId = stateParams.global.groupList.selectedGroup;
    const { page } = stateParams.calls;
    const { from, to, rep } = stateParams.me.callRecordings.filterBy.filter;

    const params = { groupId, page, from, to, rep };

    try {
      const { data } = await api.delete(`/calls/recordings/${id}/delete`);

      dispatch(getCallRecordings(params));

      return true;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  });

export const downloadCallRecording = createAsyncThunk(
  'callRecordings/downloadCallRecording',
  async (callId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`http://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3`);

      return {
        htmlTable: data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  });

