import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from './formatters';

export const getRepLabels = createAsyncThunk('repLabels/getRepLabels', async (_, { rejectWithValue }) => {
  try {
    const { data: repLabels } = await api.get('/rep-labels');

    return { repLabels: formatters.constructRepLabels(repLabels.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const createRepLabel = createAsyncThunk(
  'repLabels/createRepLabel',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { label: name, color } = payload;

    try {
      const { data } = await api.post('/rep-labels', {
        name,
        color,
      });

      dispatch(getRepLabels());

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getRepLabel = createAsyncThunk('repLabels/getRepLabel', async (repLabelId, { rejectWithValue }) => {
  try {
    const { data: repLabel } = await api.get(`/rep-labels/${repLabelId}`);

    return { repLabel: formatters.constructRepLabel(repLabel) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateRepLabel = createAsyncThunk(
  'repLabels/updateRep',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { id, label: name, color } = payload;

    try {
      const { data } = await api.put(`/rep-labels/${id}`, {
        name,
        color,
      });

      dispatch(getRepLabels());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const removeRepLabel = createAsyncThunk(
  'repLabels/removeRepLabel',
  async (repLabelId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.delete(`/rep-labels/${repLabelId}`);

      dispatch(getRepLabels());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
