import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from './formatters';

export const getFiltersCountries = createAsyncThunk('settings/country-codes', async (_, { rejectWithValue }) => {
  try {
    const { data: countries } = await api.get('/settings/country-codes');

    return { data: formatters.constructCountries(countries.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getReps = createAsyncThunk('reps/getReps', async (_, { rejectWithValue }) => {
  try {
    const { data: reps } = await api.get('/reps');

    return { reps: formatters.constructReps(reps.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const createRep = createAsyncThunk('reps/createRep', async (payload = {}, { rejectWithValue, dispatch }) => {
  const { repName: firstName, phoneType, country, areaCode, phone, repID, ext, label } = payload;

  const rep_label_id = ((label === '') ? null : label);

  try {
    const { data } = await api.post('/reps', {
      firstName,
      phone: {
        type: phoneType,
        country_code: country,
        area_code: areaCode,
        number: phone,
        ext,
        rep_id: repID,
      },
      rep_label_id,
    });

    dispatch(getReps());

    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getRep = createAsyncThunk('reps/getRep', async (repId, { rejectWithValue }) => {
  try {
    const { data: rep } = await api.get(`/reps/${repId}`);

    return { rep: formatters.constructRep(rep) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateRep = createAsyncThunk('reps/updateRep', async (payload = {}, { rejectWithValue, dispatch }) => {
  const { repName: firstName, phoneType, country, areaCode, phone, repID, id, ext, label: rep_label_id } = payload;

  try {
    const { data } = await api.put(`/reps/${id}`, {
      firstName,
      phone: {
        type: phoneType,
        country_code: country,
        area_code: areaCode,
        number: phone,
        ext,
        rep_id: repID,
      },
      rep_label_id: !rep_label_id || rep_label_id === '' ? null : rep_label_id,
    });

    dispatch(getReps());

    return { data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const removeRep = createAsyncThunk('reps/removeRep', async (repId, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.delete(`/reps/${repId}`);

    dispatch(getReps());

    return { data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
