/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getActiveCalls } from './actions';

const activeCallsAdapter = createEntityAdapter({
  selectId: call => call.id,
  sortComparer: (prev, next) => prev.id - next.id,
});

const activeCallsInitState = activeCallsAdapter.getInitialState();

const activeCallsSlice = createSlice({
  name: 'activeCalls',
  initialState: {
    list: activeCallsInitState,
    loading: false,
    loaded: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get active calls
    [getActiveCalls.pending]: state => {
      state.loading = true;
    },
    [getActiveCalls.fulfilled]: (state, action) => {
      state.loaded = true;
      state.loading = false;
      activeCallsAdapter.setAll(state.list, action.payload.activeCalls);
    },
    [getActiveCalls.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default activeCallsSlice.reducer;

export const activeCallsSelectors = activeCallsAdapter.getSelectors();

export const actions = {
  ...activeCallsSlice.actions,
  getActiveCalls,
};
