export const exportToCSV = (downloadedFile) => {
  const uri = 'data:application/vnd.ms-excel;base64,';
  const disposition = downloadedFile.downloadedFile.headers['content-disposition'];
  const filename = disposition.substring(disposition.indexOf('\"') + 1, disposition.lastIndexOf('"'));

  const base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  const link = document.createElement('a');
  link.download = filename;
  link.href = uri + base64(downloadedFile.downloadedFile.data);
  link.click();
};
