import 'react-perfect-scrollbar/dist/css/styles.css';

import React, { useEffect } from 'react';
import { makeStyles, Container } from '@material-ui/core';

import { getCurrentUser } from 'src/api/_common';

import LeftDrawer from 'src/features/me/leftDrawer';
import Header from 'src/features/me/header';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    flexGrow: 1,
    // padding: theme.spacing(3,0),
    flexDirection: 'column',
  },
}));

export default function DesktopLayout({ data, children }) {
  const classes = useStyles();

  useEffect(async () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    if (window.dataLayer.length < 2 && getCurrentUser()) {
      const user = getCurrentUser();

      window.dataLayer.push(
        {
          "identity": {
            "account": {
              "id": user.id,
            },
            "user": {
              "id": user.id,
            },
          },
          "context": {
            "staging": process.env.DEVELOPMENT_LOGOUT,
            "impersonating": "false",
            "role": user.user_role,
          },
        }
      );
      window.dataLayer.push({
        "event": "refresh page",
        "identity": {
          "account": {
            "id": user.id,
          },
          "user": {
            "id": user.id,
          },
        },
        "context": {
          "staging": process.env.DEVELOPMENT_LOGOUT,
          "impersonating": "false",
          "role": user.user_role,
        },
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <Header drawerWidth={drawerWidth} />

      <LeftDrawer data={data} drawerWidth={drawerWidth} />

      <main className={classes.content}>
        <Container>
          {children}
          {/* <Footer /> */}
        </Container>
      </main>
    </div>
  );
}
