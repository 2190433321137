/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

const Slice = createSlice({
  name: 'filterBy',
  initialState: {
    isTabExpended: true,
    filter: {
      rep: '',
      from: '',
      to: '',
    },
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
    setFilterRep: (state, action) => {
      state.filter.rep = action.payload;
    },
    setFilterDate: (state, action) => {
      const { from, to } = action.payload;

      state.filter.from = format(from, 'yyyy-MM-dd');
      state.filter.to = format(to, 'yyyy-MM-dd');
    },
  },
});

export default Slice.reducer;

export const { setTabExpend, setFilterRep, setFilterDate } = Slice.actions;
