/* eslint-disable import/prefer-default-export */
import { createAxiosObject } from './_common';

const getActiveCalls = async pageNumber => {
  const axios = createAxiosObject();
  const url = `/active-calls`;

  const { data } = await axios.get(url, { params: { page: pageNumber } });

  return data;
};

export default { getActiveCalls };
