import { combineReducers } from 'redux';
import { enableMapSet } from 'immer';

import openPrcSlice from 'src/features/openPrc/state/slice';

import users from 'src/features/users/state/slice';
import integrations from 'src/features/integrations/state/slice';
import advanced from 'src/features/advanceSettings/state/slice';
import activeCalls from 'src/features/activeCalls/state/slice';
import overview from 'src/features/overview/state/slice';
import insights from 'src/features/insights/state/slice';
import group from 'src/features/groupPerformance/state/slice';

import meGlobalGroupList from 'src/features/me/state/slice';
import onboarding from 'src/features/onboarding/slice';
import filterBy from 'src/features/overview/filterBy/slice';
import performance from 'src/features/overview/performance/slice';
import sourceOfLeads from 'src/features/overview/sourceOfLeads/slice';
import leadResults from 'src/features/overview/leadResults/slice';
import callActivity from 'src/features/overview/callActivity/slice';
import upcomingCalls from 'src/features/overview/upcomingCalls/slice';
import avgSpeed from 'src/features/overview/avgSpeed/slice';
import leadResponse from 'src/features/overview/leadResponse/slice';
import parkedCalls from 'src/features/overview/parkedCalls/slice';
import activeCallsMe from 'src/features/overview/activeCalls/slice';
import overviewMasterDatePicker from 'src/features/overview/masterDatePicker/slice';

import firstAttempt from 'src/features/insights/firstAttempt/slice';
import percentOfConnections from 'src/features/insights/percentOfConnections/slice';
import noConnections from 'src/features/insights/noConnections/slice';
import leadCall from 'src/features/insights/leadCall/slice';
import newLeadFlow from 'src/features/insights/newLeadFlow/slice';
import highestConnections from 'src/features/insights/highestConnections/slice';

import filterByG from 'src/features/groupPerformance/filterBy/slice';
import callAttempt from 'src/features/groupPerformance/callAttempt/slice';
import connectedCalls from 'src/features/groupPerformance/connectedCalls/slice';
import callDuration from 'src/features/groupPerformance/callDuration/slice';

import filterByC from 'src/features/callRecordings/filterBy/slice';
import calls from 'src/features/callRecordings/calls/slice';

import parkedCallsPage from 'src/features/parkedCalls/state/slice';
// import activeCallsPage from 'src/features/activeCalls/slice';

import blackoutDays from 'src/features/advanceSettings/blackoutDays/slice';
import tags from 'src/features/advanceSettings/tags/slice';

import repLabels from 'src/features/repLabels/slice';
import reps from 'src/features/reps/slice';
import locations from 'src/features/locations/slice';
import timezones from 'src/features/timezones/slice';
import groups from 'src/features/groups/slice';

enableMapSet();

export default combineReducers({
  onboarding,
  users,
  integrations,
  advanced,
  activeCalls,
  overview,
  openPrcSlice,
  insights,
  group,
  parked: parkedCallsPage,
  global: combineReducers({
    groupList: meGlobalGroupList
  }),
  calls,
  me: combineReducers({
    overview: combineReducers({
      filterBy,
      overviewMasterDatePicker,
      performance,
      sourceOfLeads,
      leadResults,
      callActivity,
      upcomingCalls,
      avgSpeed,
      leadResponse,
      parkedCalls,
      activeCallsMe,
    }),
    insights: combineReducers({
      firstAttempt,
      percentOfConnections,
      noConnections,
      leadCall,
      newLeadFlow,
      highestConnections,
    }),
    groupPerformance: combineReducers({
      filterBy: filterByG,
      callAttempt,
      connectedCalls,
      callDuration,
    }),
    callRecordings: combineReducers({
      filterBy: filterByC,
      calls,
    }),
    // activeCallsPage,
    // routingRules: combineReducers({
    //   group: combineReducers({
    //     groupList,
    //   }),
    // }),
    advanceSettings: combineReducers({
      blackoutDays,
      tags,
    }),
  }),
  repLabels,
  reps,
  locations,
  timezones,
  groups,
});
