/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from './formatters';

export const getActiveCalls = createAsyncThunk(
  'activeCalls/getActiveCalls',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { data: activeCalls } = await api.get('/active-calls', {
        params: { ...payload },
      });

      return {
        activeCalls: formatters.constructActiveCalls(activeCalls.data),
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
