/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'tags',
  initialState: {
    tags: [
      { name: 'Dispute Lead', color: '#F2A629' },
      { name: 'Appt Set', color: '#00C379' },
      { name: 'Hot Prospect', color: '#BF7A8D' },
      { name: 'Not Qualified', color: '#92B6C4' },
    ],
  },
  reducers: {
    setTagColor: (state, action) => {
      state.tags[action.payload.id] = { ...state.tags[action.payload.id], color: action.payload.color };
    },
    createNewTag: (state, action) => {
      state.tags.push(action.payload);
    },
  },
});

export const { setTagColor } = Slice.actions;
export default Slice.reducer;
