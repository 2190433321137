export const constructLocations = data => {
  if (!data) return [];

  return data.map(({ name, ...item }) => ({ label: name, ...item }));
};

export const constructLocation = data => {
  if (!data) return {};

  const { name, ...items } = data;

  return { label: name, ...items };
};
