import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';

import Header from 'src/features/onboarding/mobileHeader';
import FooterMobile from 'src/features/onboarding/mobileFooter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 3),
  },
}));

export default function MobileLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Container maxWidth="xs" className={classes.root}>
        {children}
      </Container>
      {/* <FooterMobile /> */}
    </>
  );
}
