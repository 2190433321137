/* eslint-disable no-shadow */
import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import api from 'src/services/axios';

import * as formatters from 'src/features/groupPerformance/state/formatters';
import { GROUP_STATUSES } from './types';
import { convertDateTimeLocalForStats } from 'src/helpers/time';

export const getGroupReps = createAsyncThunk('group/getGroupReps', async (payload = {}, { rejectWithValue }) => {
  const { groupId } = payload;

  try {
    const { data: reps } = await api.get('/reps');

    return {
      reps: formatters.constructReps(reps.data, groupId),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getGroupData = createAsyncThunk('group/getGroupData', async (_, { rejectWithValue, getState }) => {
  const { group, global } = getState();

  const { date, statuses, reps, groupId } = group.downloadFilters;

  const filters = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
    // 'status[]': Object.keys(statuses)
    //   .filter(statusKey => Boolean(statuses[statusKey]))
    //   .map(statusKey => GROUP_STATUSES[statusKey]),
    // 'reps[]': reps.reduce((acc, { isActive, id }) => [...acc, ...(isActive ? [id] : [])], []),
  };

  try {
    const data = await api.get('/stat/group-performance-download', {
      params: { ...filters },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return {
      // htmlTable: data,
      downloadedFile: data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getCallResults = createAsyncThunk('group/getCallResults', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: results } = await api.get('/stat/group-performance-call-results', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return { results: formatters.constructCallResults(results.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getCallDuration = createAsyncThunk('group/getCallDuration', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: duration } = await api.get('/stat/group-performance-call-duration', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return { duration: formatters.constructCallDuration(duration.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getCallAttempt = createAsyncThunk('group/getCallAttempt', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data } = await api.get('/stat/group-performance-call-results', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return { data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
