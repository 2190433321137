export const phoneFormatter = (phone) => {
  if (!phone) {
    return '';
  }

  return phone.replaceAll('-', '')
    .replaceAll(' ', '')
    .replaceAll(')', '')
    .replaceAll('(', '')
    .replace(/(\+)?(\d*)(\d{3})(\d{3})(\d{4}$)/,
      (match, g1, g2, g3, g4, g5) =>
        (g1 ? g1 : '+') + (g2 ? g2 : '1') + ' (' + g3 + ') ' + g4 + '-' + g5
    );
}

export const phoneFormatterSeparated = (countyCode, areaCode, phone) => {
  if (!areaCode || !phone) {
    return '';
  }

  const formatedCountryCode = countyCode ? ((countyCode + '').includes('+') ? countyCode : '+' + countyCode) : '+1';

  const formatedPhone = phone.replaceAll('-', '')
    .replaceAll(' ', '')
    .replaceAll(')', '')
    .replaceAll('(', '')
    .replace(/(\d*)(\d{4})/, "$1-$2");

  return `${formatedCountryCode} (${areaCode}) ${formatedPhone}`;
}