/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

export const constructConfiguration = data => {
  if (!data) {
    return null
  };

  data.caller_id_for_reps = phoneFormatter(data.caller_id_for_reps);
  data.enable_call_recording = data.enable_call_recording == true;
  data.concurrent_calls = data.concurrent_calls || 0;

  data.email_reports.forEach((emailReport) => {
    emailReport.daily = emailReport.daily == true;
    emailReport.weekly = emailReport.weekly == true;
    emailReport.monthly = emailReport.monthly == true;
  });

  return data;
};
