/* eslint-disable react/prop-types */
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { useMediaQuery, ThemeProvider, CssBaseline } from '@material-ui/core';

import theme from 'src/themes/meTheme';
import useAuth from 'src/hooks/useAuth';

import DesktopLayout from './desktopLayout';
import MobileLayout from './mobileLayout';

import { useFetchMe } from 'src/features/users/state/hooks';
import { getLoading } from 'src/features/users/state/selectors';
import { getMe } from 'src/features/users/state/selectors';

import { setRedirectUrl } from 'src/api/_common';

export default ({ children }) => {
  const isAuth = useAuth();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const isLoading = useSelector(getLoading);
  const data = useSelector(getMe);
  const isBrowser = typeof window !== "undefined";
  const location = useLocation();

  const [userIsLoaded, setUserIsLoaded] = useState(false);
  const [code, setCode] = useState(null);

  useFetchMe();

  useEffect(() => {
    if (isLoading === false) {
      setUserIsLoaded(true);
    }
  }, [isLoading])

  useEffect(() => {
    setCode(
      queryString.parse(window.location.search) && queryString.parse(window.location.search).code
        ? queryString.parse(window.location.search).code
        : null
    )
  }, [isBrowser])

  useEffect(() => {
    const element = document.getElementById('customerly-container');

    if (!data || !element) {
      return;
    }

    const userData = { ...data };

    if (userData?.customer?.is_subaccount && userData?.customer?.is_subaccount === true && element) {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }, [data])

  useEffect(() => {
    if (isAuth === false) {
      if (location.pathname.indexOf('users') >= 0 ) {
        setRedirectUrl(location.pathname);
      }

      if (!code) {
        navigate('/login/');
      } else {
        navigate('/login/?code=' + code);
      }
    }
  }, [isAuth, code]);

  return (
    (userIsLoaded === true && isAuth === true) && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {
          matches
            ? <DesktopLayout data={data}>{children}</DesktopLayout>
            : <MobileLayout data={data}>{children}</MobileLayout>
        }
      </ThemeProvider>
    )
  );
};
