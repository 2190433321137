/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

export const constructReps = (data, groupId) => {
  if (!data) return [];

  const reps = data
    .filter(({ groups }) => {
      if (!groupId || !groupId && groups.length < 1) {
        return true;
      }

      const isNonCheckedExists = !!groups.find((group) => {
        return !groupId || +group.id === +groupId;
      });

      return isNonCheckedExists;
    })
    .map(({ id, firstName, phone }) => ({
      id,
      username: firstName,
      phone: `+${phone.country_code}${phone.area_code}${phone.number}`,
      isActive: true,
    }));

  reps.forEach((item) => {
    if (item['phone']) {
      item['phone'] = phoneFormatter(item['phone']);
    }
  });

  return reps;
};

export const constructCallResults = data => {
  if (!data) return {};

  const dataKeys = [
    { dataKey: 'connections', name: 'Connected' },
    { dataKey: 'tagged', name: 'Tagged' },
  ];

  const legends = dataKeys.map(({ name }) => name);

  let labels = [];

  let sets = dataKeys.reduce((acc, { dataKey }) => ({ ...acc, [dataKey]: [] }), {});

  Object.entries(data).forEach(([number, items]) => {
    labels.push(number);

    dataKeys.forEach(({ dataKey }) => {
      sets[dataKey].push(+items[dataKey]);
    });
  });

  const dataSets = dataKeys.map(({ dataKey, name }) => ({
    label: name,
    data: sets[dataKey],
    borderWidth: 0,
  }));

  const tableDataSets = {
    columns: Object.keys(data).map((number, idx) => ({ key: idx, name: number })),
    data: dataSets.map(({ label, data }) => ({ rowName: label, rowData: data })),
  };

  return { legends, labels, dataSets, tableDataSets };
};

export const constructCallDuration = data => {
  if (!data) return {};

  const dataSets = Object.entries(data).reduce(
    (acc, [number, value]) => {
      return ({
        ...acc,
        labels: [...acc.labels, number.length > 16 ? number.substring(0, 13) + '...' : number.trim()],
        dataSets: [
          {
            ...acc.dataSets[0],
            label: '',
            data: [...(acc.dataSets[0]?.data ?? []), value],
            borderWidth: 0,
            backgroundColor: ['#38B778', '#38B778', '#38B778', '#38B778', '#38B778', '#38B778'],
          },
        ],
      })
    },
    { labels: [], dataSets: [] },
  );

  return { ...dataSets };
};
