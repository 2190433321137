import {
  parse,
  differenceInDays,
  addDays,
  startOfDay,
  format,
  addHours,
} from 'date-fns';

import { getNumberWithOrdinal } from 'src/helpers/ordinalSuffix';
import { getColors } from 'src/helpers/chartJsHelpers';

export const constructFirstAttemptPerformance = data => {
  if (!data) return {};

  const dataKeys = [
    { key: 'total', dataKey: 'total', name: 'Fresh', delimeter: true },
    { key: 'press2', dataKey: 'pressTwo', name: 'Called' },
    { key: 'connected', dataKey: 'connections', name: 'Connected' },
    { key: 'no_action', dataKey: 'opportunities', name: 'Not Called' },
  ];

  const totalKey = dataKeys.find(({ delimeter }) => delimeter).key;

  const dataSets = dataKeys.map(({ key, dataKey, name }, idx) => {
    const percent = Math.round((+data[0][key] * 100) / +data[0][totalKey] || 0);
    return {
      key: dataKey,
      name,
      value: +data[0][key],
      percent: [percent, 100 - percent],
      // backgroundColor: ['#325A73', '#92B6C4'],
      backgroundColor: [getColors()[idx], '#F6F6F6'],
    };
  });

  const labels = dataKeys.map(({ dataKey, name }) => ({ key: dataKey, name }));

  return { labels, dataSets };
};

export const constructConnectionsByAttempt = data => {
  if (!data) return {};

  const initialDataSet = { labels: [], dataSets: [{ label: 'Rate: ', data: [], fill: false }] };

  const dataSets = Object.entries(data).reduce(
    (acc, [key, value]) => ({
      labels: [...acc.labels, `${getNumberWithOrdinal(key)} Attempt`],
      dataSets: [{ ...acc.dataSets[0], data: [...acc.dataSets[0].data, +value] }],
    }),
    initialDataSet,
  );

  return { ...dataSets };
};

export const constructNoConnections = data => {
  if (!data) return {};

  const initialDataSet = { dataSets: [{ label: '', data: [], fill: false }] };

  const dataSets = Object.entries(data).reduce(
    (acc, [date, value]) => ({
      dataSets: [
        {
          ...acc.dataSets[0],
          data: [...acc.dataSets[0].data, { x: `${parse(date, 'yyyy-MM-dd', new Date())}`, y: +value }],
        },
      ],
    }),
    initialDataSet,
  );

  return { ...dataSets };
};

export const constructLeadCallVolume = data => {
  if (!data) return {};

  const dataKeys = [
    { dataKey: 'new', name: 'Fresh Leads', backgroundColor: '#325A73' },
    { dataKey: 'reattempts', name: 'Reattempts', backgroundColor: '#F2A629' },
    { dataKey: 'after_hours', name: 'After Hour Leads', backgroundColor: '#92B6C4' },
    { dataKey: 'missed', name: 'Calls Not Made', backgroundColor: '#BF7A8D' },
  ];

  const legendsSet = new Set();
  let dataItemValues = {};

  Object.entries(data).forEach(([itemKey, item]) => {
    dataKeys.forEach(({ dataKey, name }) => {
      const itemValue = item[dataKey];

      dataItemValues = {
        ...dataItemValues,
        [dataKey]: [
          ...(dataItemValues[dataKey] ?? []),
          { x: `${parse(itemKey, 'yyyy-MM-dd', new Date())}`, y: +itemValue },
        ],
      };

      if (itemValue !== null && itemValue !== undefined) {
        legendsSet.add(name);
      }
    });
  });

  const legends = [...legendsSet];
  const dataSets = dataKeys.map(({ dataKey, name }) => ({ label: name, data: dataItemValues[dataKey], fill: false }));

  return { legends, dataSets };
};

export const constructPressTwoConnectionsRate = data => {
  if (!data) return {};

  const connectionsDataKeys = [
    { dataKey: 'press2', name: 'Called' },
    { dataKey: 'connected', name: 'Connections' },
    { dataKey: 'total', name: 'Total by Attempt' },
  ];
  const pressTwoDataKeys = [
    { dataKey: 'missed', name: 'Leads Not Called' },
    { dataKey: 'total', name: 'Total by Attempt' }
  ];
  const dataByAttempt = data.by_attempt;

  let connectionsDataSets = {
    columns: dataByAttempt.map(({ attempt }) => ({ key: attempt, name: `${getNumberWithOrdinal(+attempt)} Attempt` })),
    data: connectionsDataKeys.map(({ dataKey, name }) => {
      let rowData;

      switch (dataKey) {
        case 'total':
          rowData = dataByAttempt.map(item => (item[dataKey]));
          break;
        default:
          rowData = dataByAttempt.map(item => `${item[dataKey + '_rate']}% (${item[dataKey]})`);
          break;
      }

      return {
        rowName: name,
        rowData: rowData,
      };
    })
  };

  connectionsDataSets.total = +data.total_leads;

  let pressTwoDataSets = {
    columns: dataByAttempt.map(({ attempt }) => ({ key: attempt, name: `${getNumberWithOrdinal(+attempt)} Attempt` })),
    data: pressTwoDataKeys.map(({ dataKey, name }) => {
      let rowData;

      switch (dataKey) {
        case 'total':
          rowData = dataByAttempt.map(item => (item[dataKey]));
          break;
        default:
          rowData = dataByAttempt.map(item => `${item[dataKey + '_rate']}% (${item[dataKey]})`);
          break;
      }

      return {
        rowName: name,
        rowData: rowData,
      };
    }),
  };

  pressTwoDataSets.total = +data.total_leads;

  return { connectionsDataSets, pressTwoDataSets };
};

export const constructNewLeadFlow = data => {
  if (!data) return {};

  const dataSet = [];
  let daysCout = 0;
  let minHour = 24, maxHour = -1, maxValue = 0;

  Object.entries(data).forEach(([date, value]) => {
    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    minHour = minHour > parsedDate.getHours() ? parsedDate.getHours() : minHour;
    maxHour = maxHour < parsedDate.getHours() ? parsedDate.getHours() : maxHour;
    maxValue = maxValue < +value ? +value : maxValue;
  });

  if (Object.entries(data).length > 0) {
    daysCout = differenceInDays(
      parse(Object.entries(data)[Object.entries(data).length - 1][0], 'yyyy-MM-dd HH:mm:ss', new Date()).setHours(0, 0, 0),
      parse(Object.entries(data)[0][0], 'yyyy-MM-dd HH:mm:ss', new Date()).setHours(0, 0, 0)
    ) - 1;
  }

  const labels = Object.entries(data).length > 0 ? [startOfDay(parse(Object.entries(data)[0][0], 'yyyy-MM-dd HH:mm:ss', new Date()))] : [];

  for (let i = 0; i <= daysCout; i++) {
    labels.push(addDays(labels[i], 1))
  }

  labels.forEach((label) => {
    let daySet = [];
    let testLabel = new Date(label).setHours(minHour);
    let isEmptyDay = true;

    if (data[format(testLabel, 'yyyy-MM-dd HH:mm:ss')]) {
      daySet.push({ label: format(testLabel, 'yyyy-MM-dd HH:mm:ss'), value: +data[format(testLabel, 'yyyy-MM-dd HH:mm:ss')] });
      isEmptyDay = false;
    } else {
      daySet.push({ label: format(testLabel, 'yyyy-MM-dd HH:mm:ss'), value: 0 });
    }

    for (let i = minHour; i < maxHour; i++) {
      testLabel = addHours(testLabel, 1);
      if (data[format(testLabel, 'yyyy-MM-dd HH:mm:ss')]) {
        daySet.push({ label: format(testLabel, 'yyyy-MM-dd HH:mm:ss'), value: +data[format(testLabel, 'yyyy-MM-dd HH:mm:ss')] });
        isEmptyDay = false;
      } else {
        daySet.push({ label: format(testLabel, 'yyyy-MM-dd HH:mm:ss'), value: 0 });
      }
    }

    dataSet.push({
      label: label,
      data: daySet,
      isEmpty: isEmptyDay,
    });
  })

  return { hours: { 'minHour': minHour, 'maxHour': maxHour }, maxValue, labels, dataSet, daysCout };
};

export const constructHighestConnections = data => {
  if (!data) return {};

  const dataKeys = [
    { dataKey: 'reattempts', name: 'Reattempts' },
    { dataKey: 'after_hours', name: 'After Hour Leads' },
  ];

  const labelsSet = new Set();
  let itemSets = {};
  let daysCout = 0;

  Object.entries(data).forEach(([date, value]) => {
    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    const testDate = new Date(parsedDate.valueOf());
    const startDate = new Date(testDate.setHours(0, 0, 0)).toString();

    labelsSet.add(startDate);

    dataKeys.forEach(({ dataKey, name }) => {
      itemSets = {
        ...itemSets,
        [dataKey]: {
          label: name,
          data: [...(itemSets?.[dataKey]?.data ?? []), { x: `${parsedDate}`, y: +value[dataKey] }],
          borderWidth: 0,
        },
      };
    });
  });

  if (Object.entries(data).length > 0) {
    daysCout = differenceInDays(
      parse(Object.entries(data)[Object.entries(data).length - 1][0], 'yyyy-MM-dd HH:mm:ss', new Date()),
      parse(Object.entries(data)[0][0], 'yyyy-MM-dd HH:mm:ss', new Date())
    );
  }

  const legends = dataKeys.map(({ name }) => name);
  const labels = [...labelsSet];
  const dataSets = Object.values(itemSets);

  return { legends, labels, dataSets, daysCout };
};
