import SevenSegmentRegular from './SevenSegment.ttf';

import LatoRegular from './Lato-Regular.ttf';
import LatoBold from './Lato-Bold.ttf';

import SharpGroteskBold from './SharpGrotesk-Bold.otf';
import SharpGroteskMedium from './SharpGrotesk-Medium.otf';

import SharpSansBold from './SharpSans-Bold.otf';
import SharpSansLight from './SharpSans-light.otf';
import SharpSansMedium from './SharpSans-Medium.otf';

const Lato = [
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Lato'),
      local('Lato-Medium'),
      url(${LatoRegular}) format('truetype')
    `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('Lato'),
      local('Lato-Bold'),
      url(${LatoBold}) format('truetype')
    `,
  },
]

const SevenSegment = [
  {
    fontFamily: 'SevenSegmentRegular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('SevenSegment'),
      local('SevenSegment'),
      url(${SevenSegmentRegular}) format('truetype')
    `,
  }
]

const SharpGrotesk = [
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('Lato'),
      local('SharpGrotesk-Medium'),
      url(${SharpGroteskMedium}) format('opentype')
    `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('Lato'),
      local('SharpGrotesk-bold'),
      url(${SharpGroteskBold}) format('opentype')
    `,
  },
];

const SharpSans = [
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
      local('Lato'),
      local('SharpSans-light'),
      url(${SharpSansLight}) format('opentype')
    `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('Lato'),
      local('SharpSans-Medium'),
      url(${SharpSansMedium}) format('opentype')
    `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('Lato'),
      local('SharpSans-Bold'),
      url(${SharpSansBold}) format('opentype')
    `,
  },
];

const Sharp = SharpSans.concat(SharpGrotesk).concat(SevenSegment).concat(Lato);

export default Sharp;
