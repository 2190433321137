import React from 'react';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from 'src/reducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
    // serializableCheck: {
      // ignoredActionPaths: ['payload.day', 'payload.from', 'payload.to'],
      // ignoredPaths: ['me.advanceSettings.blackoutDays'],
    // },
  }),
});

export default function Store({ element }) {
  return <Provider store={store}>{element}</Provider>;
}
