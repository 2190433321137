import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { makeStyles, Collapse } from '@material-ui/core';

import checkRole from 'src/helpers/aclHelper';

import { MainMenuItem, SubMenuItem } from 'src/components/MenuItems';
import HelpWidget from 'src/components/helpWidget';

import pages from './pages';

const useStyles = makeStyles(() => ({
  colorBlack: {
    fill: 'black',
    stroke: 'black',
  },
  popupWrapper: {
    textAlign: 'center',
    margin: '30px 20px',
  },
  popupTitle: {
    fontFamily: 'Lato',
    marginBottom: '26px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  popupButton: {
    display: 'block',
    maxWidth: '33%',
    fontFamily: 'Lato',
  },
  popupText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#2A97B7',
  },
  popupMenuWrapper: {
    justifyContent: 'space-between',
    width: '484px',
  }
}));

const navigateToPage = page => {
  if (!page.onClickMethod) {
    navigate(`/me${page.link}`, { state: { name: page.name } });
    return;
  }

  switch (page.onClickMethod) {
    case 'href':
      const { url, target } = page.link;
      window.open(url, target);
      break;
    case 'function':
      switch (page.link) {
        case 'openHelpChat':
          openHelpChat();
          break;
      }
      break;
  }

};

const openHelpChat = () => {
  const elements = document.getElementById('customerly-container').childNodes[0].childNodes;
  if (elements.length !== 1) {
    return;
  }

  elements[0].childNodes[0].childNodes[0].click();
}

const selectedPage = (pathname, link) => {
  return pathname.split('/me').pop() === link?.split('?').shift();
};


export default function NavigationMenus({ handleClose, data }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  const [userData, setUserData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    const element = document.getElementById('___white_label');
    const userData = { ...data };

    if (userData?.customer?.is_subaccount === true) {
      element.value = 0;
    } else {
      element.value = 1;
    }

    setUserData(userData);
  }, [data]);

  return pages.map(page => {
    if (
      (page.name === 'Help and Support' && userData?.customer?.is_subaccount === false)
        || (page.name !== 'Help and Support' && (!page.allowedFor || (page.allowedFor && checkRole(page.allowedFor))))
    ) {
      return (
        <React.Fragment key={page.name}>
          <MainMenuItem
            className={classes.colorBlack}
            selected={selectedPage(pathname, page.link) || (page.popup && popup)}
            button
            onClick={(event) => {
              if (page.link) {
                navigateToPage(page);
                handleClose();
              } else if (page.popup) {
                setPopup(!popup);
                setAnchorEl(event.currentTarget);
              } else {
                setOpen(!open);
              }
            }}
          >
            {page.icon}
            {page.name}
          </MainMenuItem>

          {
            page.subPages && (
              <Collapse in={open || Boolean(pathname.match('settings'))} timeout="auto" unmountOnExit>
                {
                  page.subPages.map(ele => {
                    if (!ele.allowedFor || (ele.allowedFor && checkRole(ele.allowedFor))) {
                      return (
                        <SubMenuItem
                          selected={selectedPage(pathname, ele.link)}
                          key={ele.name}
                          onClick={() => {
                            navigateToPage(ele);
                          }}
                        >
                          {ele.name}
                        </SubMenuItem>
                      )
                    }
                  })
                }
              </Collapse>
            )
          }

          {
            page.popupList && (
              <HelpWidget
                isOpen={popup}
                anchorEl={anchorEl}
                popupList={page.popupList}
                handleClosePopup={handleClosePopup}
              ></HelpWidget>
            )
          }
        </React.Fragment>
      )
    }
  });
}

NavigationMenus.defaultProps = {
  handleClose: () => { },
};
