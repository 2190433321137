/* eslint-disable react/jsx-filename-extension */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';

import Loader from 'src/images/loader1.gif';

import { getMe } from 'src/features/users/state/selectors';

const LoadingOverlay = memo(({ type }) => {
  const styles = {
    top: 0,
    bottom: 0,
  };

  switch (type) {
    case 'group':
      styles.top = 12;
      styles.bottom = 12;
      break;
    case 'groups':
      styles.top = 12;
      styles.bottom = -12;
      break;
    default:
      break;
  }

  const classesf = makeStyles(() => createStyles({
    root: {
      position: 'absolute',
      top: styles.top,
      bottom: styles.bottom,
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      minHeight: 'auto',
    },
  }),
  );

  const classes = classesf();

  const user = useSelector(getMe);

  return (
    <div data="true" className={classes.root}>
      {
        user?.customer?.is_subaccount === false
          ? (<img alt="loader" src={Loader} />)
          : (<CircularProgress sx={{ color: '#026de5', strokeLinecap: 'round' }} size={50} thickness={3} />)
      }
    </div>
  );
});

export default LoadingOverlay;
