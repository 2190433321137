import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time';

export const constructActiveCalls = data => {
  if (!data) return [];

  const newItems = data.items.map(({ initiated, ...item }) => ({
    ...item,
    initiated: initiated ? convertDateTimeUTCtoLocalFormat(initiated, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }) : 'No data',
  }));
  return newItems;
};
