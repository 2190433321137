/* eslint-disable no-shadow */
import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import api from 'src/services/axios';
import apiFormData from 'src/services/axiosFormData';

import * as formatters from 'src/features/parkedCalls/state/formatters';

export const getParkedCalls = createAsyncThunk('parked/getParkedCalls', async (payload = {}, { rejectWithValue, getState }) => {
  const { groupId, page, search, orderBy, orderDirection } = payload;

  const params = {
    group_id: groupId,
    page: page * 1 + 1,
    search,
    per_page: 10,
  };

  if (orderBy && orderDirection) {
    params[`sort[${orderBy}]`] = orderDirection.toUpperCase();
  }

  try {
    const { data: parked } = await api.get('/parked-calls', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      parked: formatters.constructParkedCalls(parked.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const removeParkedCall = createAsyncThunk(
  'parked/removeParkedCall',
  async (callId, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/parked-calls/${callId}`);

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const removeParkedCalls = createAsyncThunk(
  'parked/removeParkedCall',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { groupId, page, search, list } = payload;

    const params = {
      group_id: groupId,
      page: page * 1 + 1,
      search,
    };

    const ids = list.map(item => {
      return item.id;
    })

    try {
      const { data } = await api.post(`/parked-calls/mass-delete`, { ids });

      dispatch(getParkedCalls({ ...params }));

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const unparkParkedCall = createAsyncThunk(
  'parked/removeParkedCall',
  async (payload = {}, { rejectWithValue }) => {
    const { id } = payload;

    try {
      const { data } = await api.post(`/parked-calls/${id}/unpark`, {});

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const unparkParkedCalls = createAsyncThunk(
  'parked/removeParkedCall',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { groupId, page, search, list } = payload;

    const params = {
      group_id: groupId,
      page: page * 1 + 1,
      search,
    };

    const ids = list.map(item => {
      return item.id;
    });

    try {
      const { data } = await api.post(`/parked-calls/mass-unpark`, { ids });

      dispatch(getParkedCalls({ ...params }));

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getParkedCallsData = createAsyncThunk(
  'parked/getParkedCallsData',
  async (_, { rejectWithValue, getState }) => {
    const {
      parked: { search },
      global
    } = getState();

    const groupId = global.groupList.selectedGroup

    const params = {
      group_id: groupId,
      search,
    };

    try {
      const { data } = await api.get('/parked-calls/download', {
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      });

      return { htmlTable: data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const importParkedCallsData = createAsyncThunk(
  'parked/importParkedCallsData',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { groupId, page, search, source } = payload;

    let bodyFormData = new FormData();
    bodyFormData.append('file', source);

    const params = {
      group_id: groupId,
      page: page * 1 + 1,
      search,
    };

    try {
      const { data } = await apiFormData.post('/parked-calls/import', bodyFormData);

      dispatch(getParkedCalls({ ...params }));

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
