/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Menu, MenuItem } from '@material-ui/core';

import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import { getGroupsList, setSelectedGroup } from './slice';

function GroupsList(props) {
  const { data, selectedGroup } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const getGroupName = () => {
    if (!selectedGroup) {
      return 'All Groups';
    }
    return data.filter(({ id }) => id === selectedGroup)[0].name;
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    props.setSelectedGroup(null);
    handleClose();
  };

  const handleSelectGroup = id => {
    props.setSelectedGroup(id);
    window.$selectedGroup = id;
    handleClose();
  };

  useEffect(() => {
    props.getGroupsList();
  }, []);

  return (
    <>
      <Button aria-haspopup="true" onClick={handleClick} endIcon={<KeyboardArrowDownOutlinedIcon color="primary" />}>
        {getGroupName()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleSelectAll}>All Groups</MenuItem>
        {/* {data.map(({ id, name }) => (
          <MenuItem
            key={id}
            onClick={() => {
              handleSelectGroup(id);
            }}
            id={id}
          >
            {name}
          </MenuItem>
        ))} */}
      </Menu>
    </>
  );
}

const mapStateToProps = ({ me }) => {
  const { meGroupsList } = me;
  return { ...meGroupsList };
};

const mapDispatchToProps = { getGroupsList, setSelectedGroup };

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GroupsList));
