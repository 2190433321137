/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from './formatters';

export const getGroups = createAsyncThunk('groups/getGroups', async (_, { rejectWithValue }) => {
  try {
    const { data: groups } = await api.get('/groups');

    return { groups: formatters.constructGroups(groups.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const {
      groupName: name,
      timezone_id,
      routingRules,
      calls_paused,
      call_distribution_type,
      reattempts_disabled,
      reattempts_timeouts,
      park_lead_timeout,
      concurrent_calls,
      reps,
      working_hours,
      schedule,
      color,
    } = payload;

    const location_id = routingRules === '' ? null : routingRules;

    try {
      const { data } = await api.post('/groups', {
        name,
        timezone_id,
        location_id,
        calls_paused,
        call_distribution_type,
        reattempts_disabled,
        reattempts_timeouts,
        park_lead_timeout,
        concurrent_calls,
        reps,
        working_hours,
        schedule,
        color,
      });

      dispatch(getGroups());

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getGroup = createAsyncThunk('groups/getGroup', async (groupId, { rejectWithValue }) => {
  try {
    const { data: group } = await api.get(`/groups/${groupId}`);

    return { group: formatters.constructGroup(group) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const {
      id: groupId,
      name,
      timezone_id,
      location_id,
      calls_paused,
      call_distribution_type,
      reattempts_disabled,
      reattempts_timeouts,
      park_lead_timeout,
      concurrent_calls,
      reps,
      working_hours,
      schedule,
      allSelected,
      color,
    } = formatters.constructGroupsToRequest(payload);

    const groupReps = reps.map(({ id, enabled }) => ({ id, enabled }));

    const locationId = location_id === '' ? null : location_id;

    try {
      const { data } = await api.put(`/groups/${groupId}`, {
        name,
        timezone_id,
        location_id: locationId,
        calls_paused,
        call_distribution_type,
        reattempts_disabled,
        reattempts_timeouts,
        park_lead_timeout,
        concurrent_calls,
        reps: groupReps,
        working_hours,
        schedule,
        color,
      });

      return { data: formatters.constructGroup(data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { groupId } = payload;

    try {
      const { data } = await api.delete(`/groups/${groupId}`);

      dispatch(getGroups());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const cloneGroup = createAsyncThunk(
  'groups/cloneGroup',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { groupId, name } = payload;

    try {
      const { data } = await api.post(`/groups/${groupId}/clone`, { name });

      dispatch(getGroups());

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateGroupCallsPaused = createAsyncThunk(
  'groups/updateGroupCallsPaused',
  async (payload = {}, { rejectWithValue }) => {
    const {
      id: groupId,
      name,
      timezone_id,
      location_id,
      calls_paused,
      call_distribution_type,
      reattempts_disabled,
      reattempts_timeouts,
      park_lead_timeout,
      concurrent_calls,
      reps,
      working_hours,
      schedule,
    } = payload;

    const groupReps = reps.map(({ id, enabled }) => ({ id, enabled }));

    try {
      const { data } = await api.put(`/groups/${groupId}`, {
        name,
        timezone_id,
        location_id,
        calls_paused,
        call_distribution_type,
        reattempts_disabled,
        reattempts_timeouts,
        park_lead_timeout,
        concurrent_calls,
        reps: groupReps,
        working_hours,
        schedule,
      });

      return { data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const removeGroup = createAsyncThunk('groups/removeGroup', async (groupId, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.delete(`/groups/${groupId}`);

    dispatch(getGroups());

    return { data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const downloadGroupTemplate = createAsyncThunk(
  'groups/downloadGroupTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const { data: groupTemplate } = await api.get('/groups/import-template');

      return { groupTemplate: formatters.constructGroupTemplate(groupTemplate) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const importGroup = createAsyncThunk('groups/importGroup', async (_, { rejectWithValue }) => {
  try {
    const { data: groupImported } = await api.post('/groups/import');

    return { groupImported: formatters.constructGroupImported(groupImported) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const exportGroup = createAsyncThunk('groups/exportGroup', async (groupId, { rejectWithValue }) => {
  try {
    const { data: groupExported } = await api.get(`/groups/${groupId}`);

    return { groupExported: formatters.constructGroupExported(groupExported) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
