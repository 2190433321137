import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsDetailsView } from './selectors';
import { actions } from './slice';

export const useUsersDetailsData = () => {
  const dispatch = useDispatch();
  const isDetailsView = useSelector(getIsDetailsView);

  const toggleDetailsMode = useCallback(() => dispatch(actions.toggleDetailsMode()), [dispatch]);

  return {
    isDetailsView,

    toggleDetailsMode,
  };
};

export const useUsersActions = () => {
  const dispatch = useDispatch();

  const createUser = useCallback(user => dispatch(actions.createUser(user)), [dispatch]);
  const inviteUser = useCallback(user => dispatch(actions.inviteUser(user)), [dispatch]);
  const removeUser = useCallback(userId => dispatch(actions.removeUser(userId)), [dispatch]);
  const removeInvitedUser = useCallback(userId => dispatch(actions.removeInvitedUser(userId)), [dispatch]);
  const updateUser = useCallback(user => dispatch(actions.updateUser(user)), [dispatch]);
  const updateInvitedUser = useCallback(user => dispatch(actions.updateInvitedUser(user)), [dispatch]);
  const updateAvatar = useCallback(avatar => dispatch(actions.updateAvatar(avatar)), [dispatch]);
  const deleteAvatar = useCallback(() => dispatch(actions.deleteAvatar()), [dispatch]);

  return {
    createUser,
    inviteUser,
    removeUser,
    removeInvitedUser,
    updateUser,
    updateInvitedUser,
    updateAvatar,
    deleteAvatar,
  };
};

export const useFetchUsers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUsers());
    dispatch(actions.getInvitedUsers());
  }, [dispatch]);
};

export const useFetchMe = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMe());
  }, [dispatch]);
};

export const useFetchBillingPortal = () => {
  const dispatch = useDispatch();

  const getBillingData = useCallback(() => dispatch(actions.getBillingPortal()), [dispatch]);

  return { getBillingData }
};