import { createTheme, responsiveFontSizes } from '@material-ui/core';

import defaultTheme from './defaultTheme';

const theme = createTheme(defaultTheme, {
  typography: {
    h1: {
      fontSize: 33,
      fontWeight: 700,
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      marginBottom: 24,
      color: '#2e3341',
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
    },

    h6: {
      fontSize: 15,
      fontWeight: 700,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: 1,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: defaultTheme.palette.text.primary,
    },
    button: {
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
      // lineHeight:1.2
    },
    caption: {
      fontSize: 12,
      color: '#20223980',
      // color: '#8b8c97',
      fontWeight: 400,
    },
    h4: {
      // fontFamily: 'Lato',
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: '#fbfbfb',
      },
    },
    MuiFormLabel: {
      root: {
        '@media (max-width: 375px)': {
          fontSize: 14,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 10,
      },
    },
    MuiLink: {
      root: {
        fontWeight: 700,
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: 'transparent',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#2a97b7',
      },
      iconButton: {
        backgroundColor: 'transparent',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          height: 0,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },

    MuiStepConnector: {
      root: {
        display: 'none',
      },
    },
    MuiStepLabel: {
      active: {
        color: '#ffffff',
        fontWeight: '700',
      },
    },
  },
});

export default responsiveFontSizes(theme);
