/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import { createSlice } from '@reduxjs/toolkit';

import { getGroups, createGroup, removeGroup, updateGroup, deleteGroup, cloneGroup, updateGroupCallsPaused } from './actions';

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    temp: [],
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    addGroupCallAttempts: (state, { payload }) => {
      if (state.temp.length < 1) {
        state.temp = JSON.parse(JSON.stringify(state.list));
      }

      state.list.forEach(group => {
        if (group.id == payload.id) {
          group.reattempts_timeouts.push(0);
        }
      });
    },
    cancelGroupCallAttempts: (state, { payload }) => {
      if (state.temp.length < 1) {
        return;
      }

      state.list = JSON.parse(JSON.stringify(state.temp));
      state.temp = [];
    },
    deleteGroupCallAttempts: (state, { payload }) => {
      const { groupId, index } = payload;

      const list = JSON.parse(JSON.stringify(state.list))

      if (state.temp.length < 1) {
        state.temp = JSON.parse(JSON.stringify(state.list));
      }

      list.forEach(group => {
        if (group.id == groupId) {
          group.reattempts_timeouts.splice(index, 1);
        }
      });

      state.list = list;
    },
    updateGroupCallAttempts: (state, { payload }) => {
      const { groupId, index, timeout, days, hours, minutes } = payload;

      if (state.temp.length < 1) {
        state.temp = JSON.parse(JSON.stringify(state.list));
      }

      state.list.forEach(group => {
        if (group.id * 1 === groupId * 1) {
          group.reattempts[index] = {
            days,
            hours,
            minutes,
          };

          group.reattempts_timeouts[index] = (days ? parseInt(hours) * 24 * 60 : 0) + (hours ? (parseInt(hours) * 60) : 0) + (minutes ? (parseInt(minutes)) : 0);
        }

        return group;
      });
    },
    updateGroupReattemptsDisabled: (state, { payload }) => {
      if (state.temp.length < 1) {
        state.temp = JSON.parse(JSON.stringify(state.list));
      }

      state.list.forEach(group => {
        if (group.id == payload.id) {
          group.reattempts_disabled = payload.reattempts_disabled;
        }
      });
    },
  },
  extraReducers: {
    // get groups
    [getGroups.pending]: state => {
      state.list = [];
      state.loading = true;
    },
    [getGroups.fulfilled]: (state, action) => {
      const { groups } = action.payload;

      groups.forEach(group => {
        group.loading = false;
      });

      state.list = [ ...state.list, ...groups ];
      state.loading = false;
    },
    [getGroups.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // create group
    [createGroup.pending]: state => {
      state.loading = true;
    },
    [createGroup.fulfilled]: state => {
      state.loading = false;
    },
    [createGroup.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // remove group
    [removeGroup.pending]: state => {
      state.loading = true;
    },
    [removeGroup.fulfilled]: state => {
      state.loading = false;
    },
    [removeGroup.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update group
    [updateGroup.pending]: (state, { meta }) => {
      const { arg } = meta;

      state.list.forEach(group => {
        if (group.id == arg.id) {
          group.loading = true;
        }
      });
    },
    [updateGroup.fulfilled]: (state, { payload }) => {
      const { data } = payload.data;

      const list = JSON.parse(JSON.stringify(state.list));

      list.forEach(group => {
        if (group.id == data.id) {
          Object.keys(data).forEach((key) => {
            if (key !== 'id') {
              group[key] = data[key];
            }
          })

          group.loading = false;
        }
      });

      state.list = list;
    },
    [updateGroup.rejected]: (state, { payload, error }) => {
      state.error = payload?.message ?? error.message;
      state.loading = false;
    },

    // update group calls pause
    [updateGroupCallsPaused.pending]: (state, { meta }) => {
      const { arg } = meta;

      state.list.forEach(group => {
        if (group.id === arg.id) {
          group.loading = true;
        }
      });
    },
    [updateGroupCallsPaused.fulfilled]: (state, { payload }) => {
      const { data } = payload.data;

      state.list.forEach(group => {
        if (group.id === data.id) {
          group.calls_paused = data.calls_paused;
          group.loading = false;
        }

        return group;
      });
    },
    [updateGroupCallsPaused.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default groupsSlice.reducer;

export const actions = {
  ...groupsSlice.actions,
  getGroups,
  createGroup,
  removeGroup,
  updateGroup,
  deleteGroup,
  cloneGroup,
  updateGroupCallsPaused,
};
