/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Drawer, Stepper, StepLabel, Step, Avatar } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import Logo from 'src/components/logo';
import StepsImage from 'src/components/stepsImage';

const useStyles = makeStyles(theme => ({
  drawer: ({ drawerWidth }) => ({
    width: drawerWidth,
    flexShrink: 0,
  }),
  drawerPaper: ({ drawerWidth }) => ({
    width: drawerWidth,
  }),
  logo: {
    // marginLeft: 65,
    // marginRight: 'auto',
    // margin: '18px 0',
    // width: 272,
    margin: 'auto',
    width: 185,
  },
  toolbar: theme.mixins.toolbar,
  stepperContainer: {
    width: 258,
    marginLeft: 65,
    marginRight: 'auto',
    flexGrow: 1,
  },
  stepper: {
    height: '100%',
    padding: 0,
    justifyContent: 'center',
    '& > *': {
      margin: '18px 0',
    },
  },
  step: {
    borderRadius: 5,
    padding: 10,
  },
  stepActive: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiStepLabel-active': {
      color: '#ffffff',
      fontWeight: 700,
    },
  },
  icon: {
    minWidth: 72,
  },
  avatar: {
    backgroundColor: 'unset',
    color: '#bbbcc3',
    border: '2px solid #bbbcc3',
    width: 30,
    height: 30,
    marginRight: 20,
    fontSize: 18,
    fontWeight: 700,
  },
  avatarActive: {
    backgroundColor: 'unset',
    color: '#ffffff',
    border: '2px solid #ffffff',
  },
  avatarDone: {
    backgroundColor: '#00C379',
    color: '#ffffff',
    border: '2px solid #00C379',
  },
  image: {
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: -1,
    height: 270,
  },
}));

const isStepSkipped = (step, skipped) => {
  return Boolean(skipped.filter(ele => ele === step).length);
};

const HorizontalLinearStepper = props => {
  const { activeStep, steps, skipped } = props;
  const classes = useStyles();

  return (
    <div className={classes.stepperContainer}>
      <Stepper activeStep={activeStep - 1} orientation="vertical" className={classes.stepper}>
        {steps.map((stepName, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index + 1, skipped)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={index}
              {...stepProps}
              className={clsx(classes.step, index === activeStep - 1 ? classes.stepActive : false)}
            >
              <StepLabel
                {...labelProps}
                StepIconComponent={props => {
                  const { completed, active, icon } = props;
                  return completed ? (
                    <Avatar className={clsx(classes.avatar, classes.avatarDone)}>
                      <Done />
                    </Avatar>
                  ) : (
                    <Avatar className={clsx(classes.avatar, active ? classes.avatarActive : false)}>{icon}</Avatar>
                  );
                }}
              >
                {stepName}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

const StepImage = props => {
  const { activeStep } = props;
  let step;
  switch (activeStep) {
    case 2:
      step = 'step2';
      break;
    case 3:
      step = 'step3';
      break;
    case 4:
      step = 'step4';
      break;
    default:
      step = 'step1';
      break;
  }
  return <StepsImage step={step} {...props} />;
};

const LeftDrawer = props => {
  const { drawerWidth } = props;
  const classes = useStyles({ drawerWidth });
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <div className={classes.logo}>
        <Logo />
      </div>
      <HorizontalLinearStepper {...props} />
      <StepImage className={classes.image} {...props} />
    </Drawer>
  );
};

const mapStateToProps = ({ onboarding }) => {
  return { ...onboarding };
};

export default connect(mapStateToProps, null)(LeftDrawer);
