import { createAxiosObject } from './_common';

const getCurrentUser = async () => {
  const axios = createAxiosObject();
  const url = `/me`;

  const { data } = await axios.get(url);

  return data;
};

const getGroupsList = async () => {
  const axios = createAxiosObject();
  const url = `/groups`;

  const { data } = await axios.get(url);

  return data;
};

const getAdvancedSettings = async () => {
  const axios = createAxiosObject();
  const url = `/settings/advanced`;

  const { data } = await axios.get(url);

  return data;
};

const setSettingsCallsPaused = async (request) => {
  const axios = createAxiosObject();
  const url = `/settings/pause-calls`;

  const { data } = await axios.post(url, request);

  return data;
};

export default { getCurrentUser, getGroupsList, getAdvancedSettings, setSettingsCallsPaused };
