const STATUS_WAITING = 0;
const STATUS_IN_PROGRESS = 1;
const STATUS_COMPLETED = 2;
const STATUS_FAILED = 3;

const CALL_SCORE_STATUS_WAITING = 0;
const CALL_SCORE_STATUS_PROCESSING = 1;
const CALL_SCORE_STATUS_COMPLETED = 2;
const CALL_SCORE_STATUS_FAILED = 3;

const STATUS = [];
STATUS[STATUS_WAITING] = 'Waiting';
STATUS[STATUS_IN_PROGRESS] = 'In progress';
STATUS[STATUS_COMPLETED] = 'Completed';
STATUS[STATUS_FAILED] = 'Failed';

const CALL_SCORE_STATUS = [];
CALL_SCORE_STATUS[CALL_SCORE_STATUS_WAITING] = 'Waiting';
CALL_SCORE_STATUS[CALL_SCORE_STATUS_PROCESSING] = 'In progress';
CALL_SCORE_STATUS[CALL_SCORE_STATUS_COMPLETED] = 'Completed';
CALL_SCORE_STATUS[CALL_SCORE_STATUS_FAILED] = 'Failed';

export const constructCallScoreData = (data) => {
  const { status, statusCallScore, callScore } = data;

  switch (parseInt(status)) {
    case STATUS_IN_PROGRESS:
    case STATUS_FAILED:
      return STATUS[status];
    case STATUS_COMPLETED:
      if (parseInt(statusCallScore) === CALL_SCORE_STATUS_COMPLETED) {
        return callScore;
      }

      return CALL_SCORE_STATUS[statusCallScore];
    case STATUS_WAITING:
    default:
      return '-';
  }
};

export const constructCallDetailData = data => {
  const { status, statusCallScore } = data;

  switch (parseInt(status)) {
    case STATUS_IN_PROGRESS:
    case STATUS_FAILED:
      return STATUS[status];
    case STATUS_COMPLETED:
      if (parseInt(statusCallScore) === CALL_SCORE_STATUS_COMPLETED) {
        return 'button';
      }

      return CALL_SCORE_STATUS[statusCallScore];
    case STATUS_WAITING:
    default:
      return '-';
  }
};