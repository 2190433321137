import React from 'react';

import {ThemeProvider, CssBaseline } from '@material-ui/core';

import theme from 'src/themes/homeTheme';

export default ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
