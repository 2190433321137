/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Stepper, ButtonBase, StepLabel, Step, Avatar, Container } from '@material-ui/core';
import { Done, ChevronLeft } from '@material-ui/icons';

import Logo from 'src/components/logo';

import { nextStep, skipStep, previousStep } from './slice';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
  },
  container: {
    display: 'flex',
    padding: theme.spacing(3, 0),
  },
  logoWrapper: {
    maxWidth: 200,
    margin: 'auto',
    flexGrow: 1,
  },
  stepperContainer: {
    padding: theme.spacing(3, 0),
  },
  stepper: {
    height: '100%',
    padding: 0,
    justifyContent: 'center',
    '& .MuiStepConnector-root': {
      display: 'none',
    },
  },
  step: {
    '& .MuiStepLabel-label': {
      display: 'none',
    },
  },
  stepActive: {
    '& .MuiStepLabel-active': {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      display: 'block',
      fontWeight: 700,
      marginTop: theme.spacing(1),
    },
  },

  avatar: {
    backgroundColor: 'unset',
    color: '#bbbcc3',
    border: '2px solid #bbbcc3',
    width: 30,
    height: 30,
    margin: 'auto',
    fontSize: 18,
    fontWeight: 700,
  },
  avatarActive: {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
    border: '2px solid ',
  },
  avatarDone: {
    backgroundColor: '#00C379',
    color: '#ffffff',
    border: '2px solid #00C379',
  },
}));

const isStepSkipped = (step, skipped) => {
  return Boolean(skipped.filter(ele => ele === step).length);
};

const HorizontalLinearStepper = props => {
  const { activeStep, steps, skipped } = props;
  const classes = useStyles();

  return (
    <div className={classes.stepperContainer}>
      <Stepper activeStep={activeStep - 1} orientation="horizontal" alternativeLabel className={classes.stepper}>
        {steps.map((stepName, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index + 1, skipped)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={index}
              {...stepProps}
              className={clsx(classes.step, index === activeStep - 1 ? classes.stepActive : false)}
            >
              <StepLabel
                {...labelProps}
                StepIconComponent={props => {
                  const { completed, active, icon } = props;
                  return completed ? (
                    <Avatar className={clsx(classes.avatar, classes.avatarDone)}>
                      <Done />
                    </Avatar>
                  ) : (
                    <Avatar className={clsx(classes.avatar, active ? classes.avatarActive : false)}>{icon}</Avatar>
                  );
                }}
              >
                {stepName}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* <div>{steps[state.activeStep - 1].name}</div> */}
    </div>
  );
};

const BackButton = props => {
  const { activeStep } = props;
  return (
    activeStep !== 1 && (
      <ButtonBase
        onClick={() => {
          props.previousStep();
        }}
      >
        <ChevronLeft />
      </ButtonBase>
    )
  );
};

const MobileHeader = props => {
  const { drawerWidth } = props;
  const classes = useStyles({ drawerWidth });
  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <div className={classes.container}>
          <BackButton {...props} />
          <div className={classes.logoWrapper}>
            <Logo />
          </div>
        </div>
        <HorizontalLinearStepper {...props} />
      </Container>
    </div>
  );
};

const mapDispatchToProps = { nextStep, skipStep, previousStep };

const mapStateToProps = ({ onboarding }) => {
  return { ...onboarding };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
