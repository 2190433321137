import axios from 'axios';
import jwtDecode from 'jwt-decode';

const setRedirectUrl = path => {
  localStorage.setItem('redirect_url', JSON.stringify(path));
};

const removeRedirectUrl = () => {
  localStorage.removeItem('redirect_url');
};

const getRedirectUrl = () => {
  return JSON.parse(localStorage.getItem('redirect_url'));
};

const setCurrentUser = user => {
  localStorage.setItem('current_user', JSON.stringify(user));
};

const removeCurrentUser = () => {
  localStorage.removeItem('current_user');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('current_user'));
};

const setAccessToken = token => {
  localStorage.setItem('access_token', token);
};

const removeAccessToken = () => {
  localStorage.removeItem('access_token');
};

const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

const isAccessTokenValid = () => {
  const token = getAccessToken();

  if (token) {
    const decodedToken = jwtDecode(token);
    const dateNow = new Date();

    // return decodedToken.exp * 1000 > dateNow.getTime();
    return true; // always valid
  }

  return false;
};

const createAxiosObject = () => {
  const temp = axios.create({
    baseURL: `${process.env.GATSBY_API_URL}/${process.env.GATSBY_API_ROOT}`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  return temp;
}

export {
  setRedirectUrl,
  getRedirectUrl,
  removeRedirectUrl,
  setAccessToken,
  getAccessToken,
  removeAccessToken,
  isAccessTokenValid,
  createAxiosObject,
  setCurrentUser,
  removeCurrentUser,
  getCurrentUser
};
