import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';

import Header from 'src/features/onboarding/header';
import Footer from 'src/features/onboarding/footer';
import Stepper from 'src/features/onboarding/stepper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: '#f6f6f6',
    // padding: '120px 82px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-around',
  },
  contentWrapper: {
    height: '100%',
    width: '100%',
  },
}));

export default function DesktopLayout({ children }) {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.down('md'));
  const drawerWidth = matches ? 400 : 500;

  return (
    <div className={classes.root}>
      <Stepper drawerWidth={drawerWidth} />
      <div className={classes.content}>
        <div className={classes.contentWrapper}>
          <Header drawerWidth={drawerWidth} />
          {children}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
