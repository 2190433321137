import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  getMe,
  createUser,
  removeUser,
  updateUser,
  updateInvitedUser,
  getUsers,
  getInvitedUsers,
  inviteUser,
  updateAvatar,
  deleteAvatar,
  removeInvitedUser,
  getBillingPortal,
} from './actions';

// const usersAdapter = createEntityAdapter({
//   selectId: user => user.id,
//   sortComparer: (prev, next) => prev.id - next.id,
// });

// const usersInitState = usersAdapter.getInitialState({
//   isLoading: false,
//   error: null,
// });

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    me: null,
    isDetailsView: true,
    // list: usersInitState,
    usersList: {
      isLoading: false,
      list: null,
    },
    invitedUsersList: {
      isLoading: false,
      list: null,
    },
    // loading: false, 
    error: null,
    billingPortal: null
  },
  reducers: {
    toggleDetailsMode: state => {
      state.isDetailsView = !state.isDetailsView;
    },
    // updateUser: (state, { payload }) => {
    //   usersAdapter.updateOne(state, payload);
    // },
  },
  extraReducers: {
    // get me
    [getMe.pending]: state => {
      state.loading = true;
    },
    [getMe.fulfilled]: (state, action) => {
      state.loading = false;
      state.me = action.payload.me;
    },
    [getMe.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // billing portal
    [getBillingPortal.pending]: state => {
      // state.loading = true;
    },
    [getBillingPortal.fulfilled]: (state, action) => {
      // state.loading = false;
      state.billingPortal = action.payload.configuration;
    },
    [getBillingPortal.rejected]: (state, { payload, error }) => {
      // state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // create user
    [createUser.pending]: state => {
      state.usersList.isLoading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      state.usersList.isLoading = false;
      // usersAdapter.addOne(state, action.payload);
    },
    [createUser.rejected]: (state, { payload, error }) => {
      state.usersList.isLoading = false;
      state.usersList.error = payload?.message ?? error.message;
    },

    // invite user
    [inviteUser.pending]: state => {
      state.invitedUsersList.isLoading = true;
    },
    [inviteUser.fulfilled]: (state, action) => {
      state.invitedUsersList.isLoading = false;
    },
    [inviteUser.rejected]: (state, { payload, error }) => {
      state.invitedUsersList.isLoading = false;
      state.invitedUsersList.error = payload?.message ?? error.message;
    },

    // get users
    [getUsers.pending]: state => {
      state.usersList.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.usersList.isLoading = false;
      state.usersList.list = action.payload.users;
      // usersAdapter.upsertMany(state.list, action.payload.users);
    },
    [getUsers.rejected]: (state, { payload, error }) => {
      state.usersList.isLoading = false;
      state.usersList.error = payload?.message ?? error.message;
    },

    // get invited users
    [getInvitedUsers.pending]: state => {
      state.invitedUsersList.isLoading = true;
    },
    [getInvitedUsers.fulfilled]: (state, action) => {
      state.invitedUsersList.isLoading = false;
      state.invitedUsersList.list = action.payload.users;
      // usersAdapter.upsertMany(state.list, action.payload.users);
    },
    [getInvitedUsers.rejected]: (state, { payload, error }) => {
      state.invitedUsersList.isLoading = false;
      state.invitedUsersList.error = payload?.message ?? error.message;
    },

    // remove user
    [removeUser.pending]: state => {
      state.loading = true;
    },
    [removeUser.fulfilled]: (state, action) => {
      state.loading = false;
      // usersAdapter.removeAll(state.list);
    },
    [removeUser.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // remove invited user
    [removeInvitedUser.pending]: state => {
      state.loading = true;
    },
    [removeInvitedUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [removeInvitedUser.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update user
    [updateUser.pending]: state => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update invited user
    [updateInvitedUser.pending]: state => {
      state.loading = true;
    },
    [updateInvitedUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateInvitedUser.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update me avatar
    [updateAvatar.pending]: state => {
      state.loading = true;
    },
    [updateAvatar.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAvatar.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // delete me avatar
    [deleteAvatar.pending]: state => {
      state.loading = true;
    },
    [deleteAvatar.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteAvatar.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});
export default usersSlice.reducer;

// export const usersSelectors = usersAdapter.getSelectors();

export const actions = {
  ...usersSlice.actions,
  createUser,
  inviteUser,
  removeUser,
  removeInvitedUser,
  updateUser,
  updateInvitedUser,
  updateAvatar,
  deleteAvatar,
  getMe,
  getUsers,
  getInvitedUsers,
  getBillingPortal,
};
