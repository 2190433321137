/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { exportToExcel } from 'src/helpers/exportToExcel';
import { exportToCSV } from 'src/helpers/exportToCSV';
import {
  startOfDay,
  endOfDay,
  addSeconds
} from 'date-fns';
import {
  getPerformance,
} from './actions';

const performanceInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
  totalSet: {},
};

const openPrcSlice = createSlice({
  name: 'openPrc',
  initialState: {
    performance: performanceInitState,
    date: { from: new Date().toISOString(), to: new Date().toISOString() },
    groupId: null,
  },
  reducers: {
    setReportingDate: (state, { payload }) => {
      state.date = payload;
    },
  },
  extraReducers: {
    // get performance
    [getPerformance.pending]: state => {
      state.performance.isLoading = true;
    },
    [getPerformance.fulfilled]: (state, action) => {
      state.performance.isLoading = false;
      state.performance = { ...state.performance, ...action.payload.performance };
    },
    [getPerformance.rejected]: (state, { payload, error }) => {
      state.performance.isLoading = false;
      state.performance.error = payload?.message ?? error.message;
    }
  },
});

export default openPrcSlice.reducer;

export const actions = {
  ...openPrcSlice.actions,
  getPerformance,
};
