/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import equal from 'fast-deep-equal';

import overviewAPI from 'src/api/overview';

export const getCallActivity = createAsyncThunk(
  'callActivity/get',
  async arg => {
    return overviewAPI.getCallActivity(arg);
  },
  // {
  //   condition: (arg, thunkAPI) => {
  //     const state = thunkAPI.getState();
  //     const { callActivity } = state.me.overview;

  //     return !equal(callActivity.arg, arg);
  //   },
  // },
);

const Slice = createSlice({
  name: 'callActivity',
  initialState: {
    isTabExpended: true,
    legend: ['1st Attempt', '2nd Attempt', '3rd Attempt', '4th Attempt', '5th Attempt', '6th Attempt'],
    status: 'loading',
    message: '',
    data: {},
    arg: false,
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
  },
  extraReducers: {
    [getCallActivity.pending]: (state, action) => {
      state.status = 'loading';
      state.arg = action.meta.arg;
    },
    [getCallActivity.fulfilled]: (state, action) => {
      const { status, message, data } = action.payload;
      state.status = status;
      state.message = message;
      state.data = data;
    },
    [getCallActivity.rejected]: () => {},
  },
});

export const { setTabExpend } = Slice.actions;
export default Slice.reducer;
