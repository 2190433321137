import { setAccessToken, isAccessTokenValid, getAccessToken, createAxiosObject } from './_common';

const login = async ({ email, password }) => {
  const axios = createAxiosObject();
  const url = '/login';

  const { data } = await axios.post(url, { email, password });

  const { status, message, data: response } = data;

  if (status === 'success') {
    setAccessToken(response.access_token);
  }

  return { status, message };
};

const resetPassword = async ({ token, password, confirmPassword }) => {
  const axios = createAxiosObject();

  const url = '/password_reset/set_new';

  const requestData = {
    token,
    password,
    password_confirm: confirmPassword
  }

  const { data } = await axios.post(url, requestData);

  const { status, message, data: response } = data;

  if (status === 'success') {
    setAccessToken(response.access_token);
  }

  return { status, message };
};

const acceptInvite = async ({ token, name, username, phone, password, confirmPassword }) => {
  const axios = createAxiosObject();

  const url = `/accept-invite/${token}`;

  const requestData = {
    name,
    username,
    phone,
    password,
    password_confirm: confirmPassword
  }

  const responce = await axios.post(url, requestData);

  const { status, message, data } = responce.data;

  if (status === 'success') {
    setAccessToken(data.access_token);
  }

  return { status, message };
};

const sendResetPassword = async (email) => {
  const axios = createAxiosObject();

  const url = '/password_reset/send_email';

  const requestData = {
    email_username: email
  };

  const { data } = await axios.post(url, requestData);

  const { status, message } = data;

  return { status, message };
};


const getToken = async ({ code }) => {
  const axios = createAxiosObject();
  const url = '/get-token';

  const { data } = await axios.post(url, { code: code });

  const { status, message, data: response } = data;

  if (status === 'success') {
    setAccessToken(response.access_token);
  }

  return { status, message };
};

export default login;

export { resetPassword, sendResetPassword, getAccessToken, acceptInvite, isAccessTokenValid, getToken };
