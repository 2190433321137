/* eslint-disable import/prefer-default-export */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Store from './src/store';

export const wrapRootElement = Store;

export const onRouteUpdateDelayed = () => {
  const loader = document.getElementById('___loader');
  const loaderWhiteLabel = document.getElementById('___loader_white_label');
  const isWhiteLabel = document.getElementById('___white_label');

  if (isWhiteLabel && isWhiteLabel.value && isWhiteLabel.value == 1) {
    loaderWhiteLabel.style.display = 'flex';
    loaderWhiteLabel.style.zIndex = 1000;
  } else {
    loader.style.display = 'flex';
    loader.style.zIndex = 1000;
  }
};

export const onRouteUpdate = () => {
  document.getElementById('___loader').style.display = 'none';
  document.getElementById('___loader_white_label').style.display = 'none';
  document.body.style = null;
};
