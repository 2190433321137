
export const getGroupListData = state => state.global.groupList.data;

export const getSelectedGroup = state => state.global.groupList.selectedGroup;

export const getGroupListStatus = state => state.global.groupList.status;

export const getGroupListMessage = state => state.global.groupList.message;

export const getSettingsData = state => state.global.groupList.settings;

export const getSettingsCallsPausedData = state => state.global.groupList.settings.calls_paused;

