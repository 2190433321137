// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-accept-invitation-jsx": () => import("./../../../src/pages/accept-invitation.jsx" /* webpackChunkName: "component---src-pages-accept-invitation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-me-active-calls-jsx": () => import("./../../../src/pages/me/active-calls.jsx" /* webpackChunkName: "component---src-pages-me-active-calls-jsx" */),
  "component---src-pages-me-call-recordings-jsx": () => import("./../../../src/pages/me/call-recordings.jsx" /* webpackChunkName: "component---src-pages-me-call-recordings-jsx" */),
  "component---src-pages-me-group-performance-jsx": () => import("./../../../src/pages/me/group-performance.jsx" /* webpackChunkName: "component---src-pages-me-group-performance-jsx" */),
  "component---src-pages-me-insights-jsx": () => import("./../../../src/pages/me/insights.jsx" /* webpackChunkName: "component---src-pages-me-insights-jsx" */),
  "component---src-pages-me-overview-jsx": () => import("./../../../src/pages/me/overview.jsx" /* webpackChunkName: "component---src-pages-me-overview-jsx" */),
  "component---src-pages-me-parked-calls-jsx": () => import("./../../../src/pages/me/parked-calls.jsx" /* webpackChunkName: "component---src-pages-me-parked-calls-jsx" */),
  "component---src-pages-me-settings-advance-settings-jsx": () => import("./../../../src/pages/me/settings/advance-settings.jsx" /* webpackChunkName: "component---src-pages-me-settings-advance-settings-jsx" */),
  "component---src-pages-me-settings-integrations-jsx": () => import("./../../../src/pages/me/settings/integrations.jsx" /* webpackChunkName: "component---src-pages-me-settings-integrations-jsx" */),
  "component---src-pages-me-settings-routing-rules-jsx": () => import("./../../../src/pages/me/settings/routing-rules.jsx" /* webpackChunkName: "component---src-pages-me-settings-routing-rules-jsx" */),
  "component---src-pages-me-settings-users-jsx": () => import("./../../../src/pages/me/settings/users.jsx" /* webpackChunkName: "component---src-pages-me-settings-users-jsx" */),
  "component---src-pages-onboarding-index-jsx": () => import("./../../../src/pages/onboarding/index.jsx" /* webpackChunkName: "component---src-pages-onboarding-index-jsx" */),
  "component---src-pages-onboarding-steps-1-jsx": () => import("./../../../src/pages/onboarding/steps/1.jsx" /* webpackChunkName: "component---src-pages-onboarding-steps-1-jsx" */),
  "component---src-pages-onboarding-steps-2-jsx": () => import("./../../../src/pages/onboarding/steps/2.jsx" /* webpackChunkName: "component---src-pages-onboarding-steps-2-jsx" */),
  "component---src-pages-onboarding-steps-3-jsx": () => import("./../../../src/pages/onboarding/steps/3.jsx" /* webpackChunkName: "component---src-pages-onboarding-steps-3-jsx" */),
  "component---src-pages-onboarding-steps-4-jsx": () => import("./../../../src/pages/onboarding/steps/4.jsx" /* webpackChunkName: "component---src-pages-onboarding-steps-4-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */)
}

