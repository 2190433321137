import { usersSelectors } from './slice';

export const getPage = state => state.users;

export const getLoading = state => state.users.loading;

export const getError = state => state.users.error;

export const getMe = state => state.users.me;

export const getIsDetailsView = state => state.users.isDetailsView;

export const getBillingPortal = state => state.users.billingPortal;

export const getUsers = state => state.users.usersList.list;

export const getInvitedUsers = state => state.users.invitedUsersList.list;

// export const getAllUsers = state => ({
//   data: usersSelectors.selectAll(state.users.list),
//   ...state.users.list,
// });