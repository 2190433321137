/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
import { parse, differenceInDays } from 'date-fns';

import { getNumberWithOrdinal } from 'src/helpers/ordinalSuffix';
import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time';

export const constructPerformance = data => {
  if (!data) return null;

  const { by_date: byDate, total } = data;

  const dataKeys = [
    { key: 'leadsPosted', originalKey: 'leads_posted', dataKey: 'posted', name: 'Leads Posted' },
    { key: 'leadsCalled', originalKey: 'leads_called', dataKey: 'called', name: 'Leads Called' },
    { key: 'callsConnected', originalKey: 'calls_connected', dataKey: 'connected', name: 'Calls Connected' },
    // { key: 'callsTagged', originalKey: 'calls_tagged', dataKey: 'tagged', name: 'Tagged Call' },
  ];

  let labels = [];

  let sets = {
    posted: [],
    called: [],
    connected: [],
    // tagged: [],
  };

  Object.keys(byDate).forEach(dateKey => {
    const {
      date,
      leads_posted: leadsPosted,
      leads_called: leadsCalled,
      calls_connected: callsConnected,
      // calls_tagged: callsTagged,
    } = byDate[dateKey];

    labels.push(date);
    sets.posted.push(+leadsPosted);
    sets.called.push(+leadsCalled);
    sets.connected.push(+callsConnected);
    // sets.tagged.push(+callsTagged);
  });

  const dataSets = dataKeys.map(({ dataKey, name }) => ({
    label: name,
    data: sets[dataKey],
    borderWidth: 0,
  }));

  const legends = dataKeys.map(({ name }) => name);

  const totalSum = total['leads_posted'];// dataKeys.reduce((acc, { originalKey }) => acc + +total[originalKey], 0);
  const totalSet = dataKeys.reduce(
    (acc, { key, originalKey, name }) => ({
      ...acc,
      [key]: {
        name,
        value: +total[originalKey],
        percent: ((+total[originalKey] * 100) / totalSum).toFixed(0),
      },
    }),
    {},
  );

  return {
    legends,
    labels,
    dataSets,
    totalSet,
  };
};
