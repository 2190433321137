import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/axios';
import * as formatters from 'src/features/integrations/state/formatters';

export const getConfiguration = createAsyncThunk('integrations/getConfiguration', async (_, { rejectWithValue }) => {
  try {
    const { data: configuration } = await api.get('/settings/integrations');

    return {
      configuration: formatters.constructConfiguration(configuration.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
