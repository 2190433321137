/* eslint-disable no-shadow */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupListData, getSelectedGroup, getGroupListStatus, getGroupListMessage, getSettingsData, getSettingsCallsPausedData } from './selectors';
import { actions } from './slice';

export const useGroupListData = () => {
  const dispatch = useDispatch();
  const data = useSelector(getGroupListData);
  const status = useSelector(getGroupListStatus);
  const message = useSelector(getGroupListMessage);
  const selectedGroup = useSelector(getSelectedGroup);

  const setSelectedGroup = useCallback((selectedGroup) => dispatch(actions.setSelectedGroup(selectedGroup)), [dispatch]);

  return {
    data,
    status,
    message,
    selectedGroup,

    setSelectedGroup,
  };
};

export const useFetchAdvancedSettingsData = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getSettingsData);
  const callsPaused = useSelector(getSettingsCallsPausedData);

  const setCallsPaused = useCallback((data) => dispatch(actions.setSettingsCallsPaused(data)), [dispatch]);

  return {
    settings,
    callsPaused,

    setCallsPaused,
  };
};

export const useFetchGroupList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getGroupsList());
  }, [dispatch]);
};

export const useFetchAdvancedSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAdvancedSettings());
  }, [dispatch]);
};