import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getReps, getFiltersCountries, createRep, removeRep, updateRep } from './actions';

const filtersCountriesAdapter = createEntityAdapter({
  selectId: country => country.id,
});

const repsAdapter = createEntityAdapter({
  selectId: rep => rep.id,
});

const repsAdapterInit = repsAdapter.getInitialState();
const filtersCountriesAdapterInit = filtersCountriesAdapter.getInitialState();

const repsSlice = createSlice({
  name: 'reps',
  initialState: {
    filters: {
      countries: {
        list: filtersCountriesAdapterInit,
        loading: false,
        error: null,
      },
    },
    list: repsAdapterInit,
    loading: false,
    loadingItem: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get filters
    [getFiltersCountries.pending]: state => {
      state.filters.countries.loading = true;
    },
    [getFiltersCountries.fulfilled]: (state, action) => {
      state.filters.countries.loading = false;

      filtersCountriesAdapter.setAll(state.filters.countries.list, action.payload.data);
    },
    [getFiltersCountries.rejected]: (state, { payload, error }) => {
      state.filters.countries.loading = false;
      state.filters.countries.error = payload?.message ?? error.message;
    },

    // get reps
    [getReps.pending]: state => {
      state.loading = true;
    },
    [getReps.fulfilled]: (state, action) => {
      state.loading = false;

      repsAdapter.setAll(state.list, action.payload.reps);
    },
    [getReps.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // create rep
    [createRep.pending]: state => {
      // state.loading = true;
    },
    [createRep.fulfilled]: state => {
      // state.loading = false;
    },
    [createRep.rejected]: (state, { payload, error }) => {
      // state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // remove rep
    [removeRep.pending]: state => {
      state.loading = true;
    },
    [removeRep.fulfilled]: state => {
      state.loading = false;
    },
    [removeRep.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update rep
    [updateRep.pending]: state => {
      state.loadingItem = true;
    },
    [updateRep.fulfilled]: state => {
      state.loadingItem = false;
    },
    [updateRep.rejected]: (state, { payload, error }) => {
      state.loadingItem = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default repsSlice.reducer;

export const repsSelectors = repsAdapter.getSelectors();

export const actions = {
  ...repsSlice.actions,
  getReps,
  getFiltersCountries,
  createRep,
  removeRep,
  updateRep,
};
