export const constructRepLabels = data => {
  if (!data) return [];

  data.unshift({color: '', id: '', name: "- empty -"});

  return data.map(({ name, ...rest }) => ({ label: name, ...rest }));
};

export const constructRepLabel = data => {
  if (!data) return {};

  return data;
};
