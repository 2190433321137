import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time'

/* eslint-disable import/prefer-default-export */
export const constructParkedCalls = data => {
  if (!data) return {};

  const { items, total } = data;

  const newItems = items.map(({ nextcall, ...item }) => ({
    ...item,
    nextcall: nextcall ? convertDateTimeUTCtoLocalFormat(nextcall) : 'No data',
  }));


  const dataKeys = [
    { dataKey: 'id', name: 'Id', hidden: true },
    { dataKey: 'name', name: 'Lead Name', cellStyle: { fontWeight: 700 } },
    { dataKey: 'phone', name: 'Lead Number' },
    { dataKey: 'routing', name: 'Routing Rule' },
    // { dataKey: 'time_zone', name: 'Time zone' },
    // { dataKey: 'nextcall', name: 'Upcoming calls', type: 'date', dateSetting: { format: 'yyyy-MM-dd' }, },
    { dataKey: 'nextcall', name: 'Upcoming Calls', type: 'date', },
    { dataKey: 'afterhours', name: 'After Hour Lead', width: 200 },
    { dataKey: 'attempt', name: 'Attempt Number', width: 200 },
  ];

  const columns = dataKeys.map(({ dataKey, name, ...dataProps }) => ({
    title: name,
    field: dataKey,
    ...dataProps
  }));

  return { columns, dataSets: newItems, totalCount: total };
};
