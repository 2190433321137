/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import qs from 'query-string';

import api from 'src/services/axios';

import * as formatters from 'src/features/openPrc/state/formatters';
import { convertDateTimeLocalForStats } from 'src/helpers/time';

export const getPerformance = createAsyncThunk('openPrc/getPerformance', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: performance } = await api.get('/stat/overview-performance', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return {
      performance: formatters.constructPerformance(performance.data),
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});
