/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getTimezones } from './actions';

const timezonesAdapter = createEntityAdapter({
  selectId: timezone => timezone.id,
});

const timezonesInit = timezonesAdapter.getInitialState();

const timezonesSlice = createSlice({
  name: 'timezones',
  initialState: {
    list: timezonesInit,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // get timezones
    [getTimezones.pending]: state => {
      state.loading = true;
    },
    [getTimezones.fulfilled]: (state, action) => {
      state.loading = false;
      timezonesAdapter.setAll(state.list, action.payload.timezones);
    },
    [getTimezones.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default timezonesSlice.reducer;

export const timezonesSelectors = timezonesAdapter.getSelectors();

export const actions = {
  ...timezonesSlice.actions,
  getTimezones,
};
