import { useState, useEffect } from 'react';
import { isAccessTokenValid } from 'src/api/login';

export default function useAuth() {
  const [isAuth, setAuth] = useState(null);

  useEffect(() => {
    const isToken = isAccessTokenValid();

    setAuth(isToken);
  }, []);

  return isAuth;
}
