/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'filterBy',
  initialState: {
    isTabExpended: true,
    checkList: {
      all: true,
      '86d': false,
      Connected: false,
      notConnected: false,
      noAnswer: false,
      taggedYes: false,
      taggedNo: false,
    },
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
    setChecked: (state, action) => {
      state.checkList[action.payload.name] = action.payload.checked;
    },
  },
});

export const { setChecked, setTabExpend } = Slice.actions;
export default Slice.reducer;
