/* eslint-disable react/prop-types */
import React from 'react';

import HomeLayout from './home';
import LoginLayout from './login';
import MeLayout from './me';
import OnboardingLayout from './onboarding';

export default ({ children, pageContext }) => {
  switch (pageContext.layout) {
    case 'login':
      return <LoginLayout>{children}</LoginLayout>;
    case 'me':
      return <MeLayout>{children}</MeLayout>;
    case 'onboarding':
      return <OnboardingLayout>{children}</OnboardingLayout>;
    default:
      return <HomeLayout>{children}</HomeLayout>;
  }
};
