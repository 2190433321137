/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/api/me';

export const getGroupsList = createAsyncThunk('me/groups/get', async () => api.getGroupsList());

export const getAdvancedSettings = createAsyncThunk('me/settings/get', async () => api.getAdvancedSettings());

export const setSettingsCallsPaused = createAsyncThunk('me/settings/pause-calls', async (data) => api.setSettingsCallsPaused(data));
