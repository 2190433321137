/* eslint-disable no-nested-ternary */
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import setHours from 'date-fns/setHours';
import addDays from 'date-fns/addDays';
import getDay from 'date-fns/getDay';
import setDay from 'date-fns/setDay';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInDays from 'date-fns/differenceInDays';
import { startOfDay } from 'date-fns';

export const formattedTime = seconds => {
  const date = new Date(0);
  date.setHours(0);
  const helperDate = addSeconds(date, Math.round(seconds));
  // return format(helperDate, 'mm:ss');
  return format(helperDate, "m 'min' s 'sec'");
};

export const formatCallTime = date => {
  const helperDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  return format(helperDate, 'h:mm aa');
};

export const getWeekDayName = date => {
  return format(date, 'EEEE');
};

export const getStartOfWeek = (date = new Date()) => {
  return startOfWeek(date, { weekStartsOn: 1 });
};

export const getEndOfWeek = (date = new Date()) => {
  return endOfWeek(date, { weekStartsOn: 1 });
};

export const getStartOfWeekWithShiftedHours = hours => {
  if (hours > 23) {
    return addDays(getStartOfWeek(), 1)
  }

  return setHours(getStartOfWeek(), hours);
};

export const setDayToInterval = (interval, day) => {
  const { start, end } = interval;

  const isFullDay = differenceInHours(end, start) === 24;

  return Object.entries(interval).reduce(
    (acc, [key, date]) => ({
      ...acc,
      [key]: setDay(date, (isFullDay === true && key === 'end' ? (day + 1) : day))
    }),
    {}
  );
};

export const convertTimeToInterval = (date, timeIntervals, intervalKeys = ['start', 'end']) => {
  return timeIntervals.map(timeInverval =>
    Object.values(timeInverval).reduce((acc, time, idx) => {
      const [hours, minutes] = time.split(':');

      const currentTimestamp = new Date(date).setHours(hours, minutes || 0, 0, 0);

      return { ...acc, [intervalKeys[idx]]: new Date(currentTimestamp) };
    }, {}),
  );
};

export const convertIntervalToTime = (intervals, intervalKeys = ['from', 'to']) => {
  return intervals.map(interval => {
    return Object.values(interval).reduce((acc, date, idx) => {
      let dateStart, dateEnd;
      if ((typeof interval.start === 'string') || (typeof interval.end === 'string')) {
        dateStart = new Date(interval.start);
        dateEnd = new Date(interval.end);
      } else {
        dateStart = interval.start;
        dateEnd = interval.end;
      }
      const currentTime = `${(idx === 1 && dateStart && dateEnd && (dateStart.getDate() !== dateEnd.getDate()))
        ? '24'
        : new Date(date).getHours().toString()
        }:${new Date(date).getMinutes() === 0
          ? '00'
          : (
            new Date(date).getMinutes() < 10
              ? (`0${new Date(date).getMinutes().toString()}`)
              : new Date(date).getMinutes().toString()
          )
        }`;

      return { ...acc, [intervalKeys[idx]]: currentTime };
    }, {});
  });
};

export const getWeekDay = dayName => {
  const startWeek = getStartOfWeek();
  const startClosestDate = parse(dayName, 'eee', new Date(), { weekStartsOn: 1 });
  const dayIdx = getDay(startClosestDate);
  const weekDay = setDay(startWeek, dayIdx, { weekStartsOn: 1 });

  return weekDay;
};

export const convertDateTimeUTCtoLocalFormat = (dateTime, customFormat = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
}) => {
  var date = new Date(dateTime.split(' ').join('T') + '.000Z');
  const options = Object.assign({}, customFormat, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options).format(date).replace(',', '');
}

export const convertDateTimeLocalFormatToUTC = (dateTime) => {
  var date = new Date(dateTime).toISOString().replace('T', ' ');
  date = date.substring(0, date.length - 5);
  return date;
}

export const convertDateTimeLocalForStats = (date) => {
  if (!date || !date.from || !date.to) {
    return null;
  }

  const formatedDate = {
    from: startOfDay(new Date(date.from)).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace(' ', 'T').replace('−', '-'),
    to: startOfDay(addDays(new Date(date.to), 1)).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace(' ', 'T').replace('−', '-')
  }

  return formatedDate;
}