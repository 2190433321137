/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { exportToExcel } from 'src/helpers/exportToExcel';
import { getParkedCalls, importParkedCallsData, getParkedCallsData, removeParkedCall, removeParkedCalls, unparkParkedCall, unparkParkedCalls } from './actions';

const callsAdapter = createEntityAdapter({
  selectId: call => call.id,
  sortComparer: (prev, next) => prev.id - next.id,
});

const callsInitState = callsAdapter.getInitialState({ columns: [] });

const parkedSlice = createSlice({
  name: 'parked',
  initialState: {
    list: callsInitState,
    page: 0,
    totalCount: 0,
    search: '',
    groupId: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    updateList: (state, { payload }) => {
      callsAdapter.setAll(state.list, payload);
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: {
    // get parked calls
    [getParkedCalls.pending]: state => {
      state.isLoading = true;
    },
    [getParkedCalls.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loaded = true;
    },
    [getParkedCalls.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
    // unpark parked call
    [unparkParkedCall.pending]: state => {
      state.isLoading = true;
    },
    [unparkParkedCall.fulfilled]: state => {
      state.isLoading = false;
    },
    [unparkParkedCall.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
    // remove parked call
    [removeParkedCall.pending]: state => {
      state.isLoading = true;
    },
    [removeParkedCall.fulfilled]: state => {
      state.isLoading = false;
    },
    [removeParkedCall.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
    // get parked data
    [getParkedCallsData.pending]: state => {
      state.isLoading = true;
    },
    [getParkedCallsData.fulfilled]: (state, action) => {
      state.isLoading = false;
      exportToExcel(action.payload.htmlTable, 'ParkedCalls');
    },
    [getParkedCallsData.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
    // import parked data
    [importParkedCallsData.pending]: state => {
      state.isLoading = true;
      state.error = null;
      state.successMessage = null;
    },
    [importParkedCallsData.fulfilled]: (state, action) => {
      const { payload } = action;
      const { success_count, fail_count, errors } = payload.data.data;

      if (fail_count < 1 && success_count > 0) {
        state.successMessage = success_count;
      }

      if (fail_count > 0) {
        state.error = errors;
      }

      state.isLoading = false;
    },
    [importParkedCallsData.rejected]: (state, { payload, error }) => {
      state.isLoading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default parkedSlice.reducer;

export const callsSelectors = callsAdapter.getSelectors();

export const { clearError, clearSuccessMessage } = parkedSlice.actions;

export const actions = {
  ...parkedSlice.actions,
  getParkedCalls,
  removeParkedCall,
  removeParkedCalls,
  unparkParkedCall,
  unparkParkedCalls,
  getParkedCallsData,
  importParkedCallsData,
};
