/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getConfiguration, searchConfiguration, updateConfiguration, deletePhoneData } from './actions';

const advancedSlice = createSlice({
  name: 'advanced',
  initialState: {
    search: {
      phone: [],
      isLoading: false,
      isEmpty: true,
    },
    configuration: {
      enable_call_recording: 0,
      calls_paused: 0,
      ivr_announce: [],
      caller_id_for_reps: '',
      caller_id_for_leads: '',
      concurrent_calls: '',
      tags: {
        enabled: 0,
        active_id: 0,
        items: []
      },
      blackout_days: [],
      disable_parking_arrived_on_blackout_day: 0,
      email_reports: [],
      data_retention_period: 0,
      dup_check: 0,
      reattempts_limit: 0,
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // search
    [searchConfiguration.pending]: state => {
      state.search.isEmpty = false;
      state.search.isLoading = true;
    },
    [searchConfiguration.fulfilled]: (state, { payload }) => {
      const { data } = payload.data;

      state.search.phone = data;
      state.search.isEmpty = !(data && data.length > 0);
      state.search.isLoading = false;
    },
    [searchConfiguration.rejected]: (state, { payload, error }) => {
      state.search.phone = [];
      state.search.isEmpty = false;
      state.search.isLoading = false;
    },

    // get advanced
    [getConfiguration.pending]: state => {
      state.loading = true;
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      state.configuration = action.payload.configuration;
    },
    [getConfiguration.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },

    // update advanced
    [updateConfiguration.pending]: state => {
      state.loading = true;
    },
    [updateConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateConfiguration.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
  },
});

export default advancedSlice.reducer;

export const actions = {
  ...advancedSlice.actions,
  getConfiguration,
  updateConfiguration,
  searchConfiguration,
  deletePhoneData,
  // useFetchAdvancedSettingsSearch,
};
