import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import api from 'src/services/axios';
// import { convertDateTimeLocalFormatToUTC } from 'src/helpers/time';
import * as formatters from 'src/features/insights/state/formatters';
import { convertDateTimeLocalForStats } from 'src/helpers/time';

export const getFirstAttemptPerformance = createAsyncThunk(
  'insights/getFirstAttemptPerformance',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: first } = await api.get('/stat/insights-first-attempt-performance', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { first: formatters.constructFirstAttemptPerformance(first.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getConnectionsByAttempt = createAsyncThunk(
  'insights/getConnectionsByAttempt',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: connections } = await api.get('/stat/insights-connections-by-attempt', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { connections: formatters.constructConnectionsByAttempt(connections.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getNoConnections = createAsyncThunk(
  'insights/getNoConnections',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: disconnected } = await api.get('/stat/insights-no-connections', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { disconnected: formatters.constructNoConnections(disconnected.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getLeadCallVolume = createAsyncThunk(
  'insights/getLeadCallVolume',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: volumes } = await api.get('/stat/insights-lead-call-volume-by-day', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { volumes: formatters.constructLeadCallVolume(volumes.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getPressTwoConnectionsRate = createAsyncThunk(
  'insights/getPressTwoConnectionsRate',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: volumes } = await api.get('/stat/insights-press2-and-connections-rate', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { volumes: formatters.constructPressTwoConnectionsRate(volumes.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getNewLeadFlow = createAsyncThunk('insights/getNewLeadFlow', async (payload = {}, { rejectWithValue }) => {
  const { date, groupId } = payload;
  // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

  const params = {
    ...convertDateTimeLocalForStats(date),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language || navigator.userLanguage,
    group_id: groupId,
  };

  try {
    const { data: flow } = await api.get('/stat/insights-new-lead-flow', {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return { flow: formatters.constructNewLeadFlow(flow.data) };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const getHighestConnections = createAsyncThunk(
  'insights/getHighestConnections',
  async (payload = {}, { rejectWithValue }) => {
    const { date, groupId } = payload;
    // const formatedDate = { from: convertDateTimeLocalFormatToUTC(date.from), to: convertDateTimeLocalFormatToUTC(date.to) };

    const params = {
      ...convertDateTimeLocalForStats(date),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language || navigator.userLanguage,
      group_id: groupId,
    };

    try {
      const { data: highest } = await api.get('/stat/insights-highest-connections', {
        params,
        paramsSerializer: params => qs.stringify(params),
      });

      return { highest: formatters.constructHighestConnections(highest.data) };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
