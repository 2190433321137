import { USER_ROLES, USER_STATUSES } from './types';
import { convertDateTimeUTCtoLocalFormat } from 'src/helpers/time';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

export const constructUser = data => {
  if (!data) return [];

  return data;
};

export const constructInvitedUser = data => {
  if (!data) return null;

  return data;
};

export const constructUserById = data => {
  if (!data) return null;

  return data;
};

export const constructInvitedUserById = data => {
  if (!data) return null;

  return data;
};

export const constructUsers = data => {
  if (!data) return [];

  return data.map(({ user_role, ...item }) => ({
    ...item,
    user: item.username || item.email,
    status: USER_STATUSES.created,
    // role: user_role,
    role: { id: user_role, value: Object.keys(USER_ROLES).find(key => USER_ROLES[key] === user_role) },
    updated: item.updated ? convertDateTimeUTCtoLocalFormat(item.updated) : 'No data',
    created: item.created ? convertDateTimeUTCtoLocalFormat(item.created) : 'No data',
    phone: item.phone ? phoneFormatter(item.phone) : '',
  }));
};

export const constructInvitedUsers = data => {
  if (!data) return [];

  return data.map(({ status, role, ...item }) => ({
    ...item,
    user: item.username || item.email,
    status: { id: status, value: Object.keys(USER_STATUSES).find(key => USER_STATUSES[key] === status) },
    role: { id: role, value: Object.keys(USER_ROLES).find(key => USER_ROLES[key] === role) },
    updated: item.updated ? convertDateTimeUTCtoLocalFormat(item.updated) : 'No data',
    created: item.created ? convertDateTimeUTCtoLocalFormat(item.created) : 'No data',
  }));
};

export const constructMe = data => {
  if (!data) return null;

  const { user_role, ...me } = data.user;

  return {
    ...me,
    role: { id: user_role, value: Object.keys(USER_ROLES).find(key => USER_ROLES[key] === user_role) },
  };
};

export const constructBillingPortal = data => {
  if (!data) return null;

  return data;
};