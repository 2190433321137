/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useLocation } from '@reach/router';

import { makeStyles, AppBar, Toolbar, IconButton, Badge, SvgIcon, Typography } from '@material-ui/core';

import NotificationsOutlinedIcon from 'src/assets/icons/notification.svg';
import MenuIcon from 'src/assets/icons/menu.svg';

import Logo from 'src/components/logo';
import MobileMenu from './mobileMenu';

import pages from './pages';

const useStyles = makeStyles(theme => ({
  appBar: {
    padding: theme.spacing(3, 0),
  },
  logoWrapper: {
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  logo: {
    width: 136,
    margin: 'auto',
  },
  textWrapper: {
    justifyContent: 'center',
  },
}));

const PageName = () => {
  const { pathname } = useLocation();

  const pageName = pages.filter(page => page.link === pathname.split('/me')[1]).pop();
  return (
    <Typography variant="h6" align="center">
      {pageName && pageName.name}
    </Typography>
  );
};

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return open ? (
    <MobileMenu onClose={handleClose} />
  ) : (
    <AppBar position="relative" color="transparent" className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton aria-label="show 17 new notifications" color="inherit" onClick={handleOpen}>
          <SvgIcon component={MenuIcon} color="secondary" />
        </IconButton>
        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} />
        </div>
        <IconButton color="inherit">
          <Badge badgeContent={2} color="primary" overlap="rectangular">
            <NotificationsOutlinedIcon color="secondary" />
          </Badge>
        </IconButton>
      </Toolbar>
      <Toolbar className={classes.textWrapper}>
        <PageName />
      </Toolbar>
    </AppBar>
  );
}
