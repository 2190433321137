/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'filterBy',
  initialState: {
    isTabExpended: true,
    callStatusCheckList: {
      all: true,
      '86d': false,
      Connected: false,
      notConnected: false,
      noAnswer: false,
      taggedYes: false,
      taggedNo: false,
    },
    groupMemberCheckList: {
      all: false,
    },
    groupMembers: [
      {
        id: 1,
        name: 'Mark Doe',
        phone: '+16096664476',
      },
      {
        id: 2,
        name: 'Mark Doe',
        phone: '+16096664476',
      },
      {
        id: 3,
        name: 'Mark Doe',
        phone: '+16096664476',
      },
      {
        id: 4,
        name: 'Mark Doe',
        phone: '+16096664476',
      },
      {
        id: 5,
        name: 'Mark Doe',
        phone: '+16096664476',
      },
    ],
  },
  reducers: {
    setTabExpend: (state, action) => {
      state.isTabExpended = action.payload;
    },
    setCallStatusChecked: (state, action) => {
      state.callStatusCheckList[action.payload.name] = action.payload.checked;
    },
    setGroupMemberCheckList: (state, action) => {
      state.groupMemberCheckList[action.payload.name] = action.payload.checked;
    },
  },
});

export const { setCallStatusChecked, setTabExpend, setGroupMemberCheckList } = Slice.actions;
export default Slice.reducer;
