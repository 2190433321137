/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'masterDatePicker',
  initialState: {
    from: new Date().toISOString(),
    to: new Date().toISOString(),
  },
  reducers: {
    setDateRange: (state, action) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  },
});

export const { setDateRange } = Slice.actions;
export default Slice.reducer;
