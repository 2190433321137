/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable eqeqeq */
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import Switch from 'src/components/switch';

import { useFetchAdvancedSettingsData, useFetchAdvancedSettings } from '../../state/hooks';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme)=> ({
  switchCallsWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  switchCalls: {
    margin: '0 10px 0 0',
  },
}));

export const HeaderPauseCallsSwitcher = () => {
  const classes = useStyles();

  const { settings, callsPaused, setCallsPaused } = useFetchAdvancedSettingsData();

  useFetchAdvancedSettings();

  const handleChange = (_event, value) => {
    setCallsPaused({ ...settings, calls_paused: value === true ? 1 : 0 });
  };

  return (
      <div className={classes.switchCallsWrap}>
        <div className={classes.switchCalls}>
          <Switch checked={callsPaused == 1} onChange={handleChange}/>
        </div>

        <Typography className={classes.switchCalls}>
          Pause all calls for all groups
        </Typography>
      </div>
  );
};