export const constructReps = data => {
  if (!data) return [];

  return data.map(({ firstName, phone, id, label, ...rep }) => ({
    repName: firstName,
    phoneType: +phone.type,
    country: +phone.country_code,
    areaCode: phone.area_code,
    phone: phone.number,
    phoneDetails: phone,
    ext: phone.ext,
    id: id,
    repID: phone?.rep_id,
    label: label?.id,
    ...rep,
  }));
};

export const constructRep = data => {
  if (!data) return {};

  return data;
};

export const constructCountries = data => {
  if (!data) return [];

  return data;
};

export const constructCountry = data => {
  if (!data) return {};

  return data;
};