/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { exportToExcel } from 'src/helpers/exportToExcel';
import { exportToCSV } from 'src/helpers/exportToCSV';
import {
  startOfDay,
  endOfDay,
  addSeconds
} from 'date-fns';
import {
  getOverviewData,
  getOverviewLeadData,
  getPerformance,
  getSources,
  getResults,
  getCallActivities,
  getUpcomingCalls,
  getAvgSpeed,
  getResponse,
  getParkedCalls,
  getParkedCallsData,
  removeParkedCall,
  getActiveCalls,
} from './actions';

const filtersInitState = {
  statuses: {
    total: true,
    '86d': true,
    connected: true,
    notConnected: true,
    noAnswer: true,
    taggedNo: true,
    taggedYes: true,
  },
  allStatuses: true,
  date: null,
  groupId: null,
  isLoading: false,
  error: null,
  isExpanded: false,
};

const performanceInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
  totalSet: {},
};

const sourcesInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  list: [],
};

const resultsInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  dataSets: [],
  labels: [],
};

const callActivitiesInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
};

const upcomingCallsInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  labels: [],
  dataSets: [],
};

const avgInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  secondaryDatasets: [],
  dataSets: [],
  date: { from: new Date().toISOString(), to: new Date().toISOString() },
};

const responseInitState = {
  isLoading: false,
  error: null,
  isExpanded: true,
  legends: [],
  secondaryDatasets: [],
  primaryDatasets: [],
};

const parkedInitState = {
  isLoading: false,
  loaded: false,
  error: null,
  isExpanded: true,
  columns: [],
  dataSets: [],
};

const activeInitState = {
  isLoading: false,
  loaded: false,
  error: null,
  isExpanded: true,
  list: [],
};

const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    filters: filtersInitState,
    filtersLead: filtersInitState,
    performance: performanceInitState,
    sources: sourcesInitState,
    results: resultsInitState,
    callActivities: callActivitiesInitState,
    upcomingCalls: upcomingCallsInitState,
    avg: avgInitState,
    response: responseInitState,
    parked: parkedInitState,
    active: activeInitState,
    // date: { from: '2010-05-05 00:00:00', to: '2022-03-08 23:59:59' },
    date: { from: new Date().toISOString(), to: new Date().toISOString() },
    groupId: null,
    loading: false,
    error: null,
  },
  reducers: {
    setGroup: (state, { payload }) => {
      state.filters.groupId = payload;
    },
    toggleFilterPanel: state => {
      state.filters.isExpanded = !state.filters.isExpanded;
    },
    toggleLeadFilterPanel: state => {
      state.filtersLead.isExpanded = !state.filtersLead.isExpanded;
    },
    toggleStatuses: state => {
      state.filters.allStatuses = !state.filters.allStatuses;
      state.filters.statuses = Object.keys(state.filters.statuses).reduce(
        (acc, key) => ({ ...acc, [key]: state.filters.allStatuses }),
        {},
      );
    },
    toggleStatus: (state, { payload }) => {
      state.filters.statuses = {
        ...state.filters.statuses,
        [payload]: !state.filters.statuses[payload]
      };

      const isNonCheckedExists = !!Object.keys(state.filters.statuses).find((key) => {
        return state.filters.statuses[key] === false;
      });

      state.filters.allStatuses = !isNonCheckedExists;
    },
    setFilterDate: (state, { payload }) => {
      state.filters.date = payload;
    },
    setFilterLeadDate: (state, { payload }) => {
      state.filtersLead.date = payload;
    },
    setFilterGroup: (state, { payload }) => {
      state.filters.groupId = payload;
    },
    setReportingDate: (state, { payload }) => {
      state.date = payload;
    },
    setAvgSpeedDate: (state, { payload }) => {
      state.avg.date = payload;
    },
    togglePerformancePanel: state => {
      state.performance.isExpanded = !state.performance.isExpanded;
    },
    toggleSourcesPanel: state => {
      state.sources.isExpanded = !state.sources.isExpanded;
    },
    toggleResultsPanel: state => {
      state.results.isExpanded = !state.results.isExpanded;
    },
    toggleCallActivitiesPanel: state => {
      state.callActivities.isExpanded = !state.callActivities.isExpanded;
    },
    toggleUpcomingCallsPanel: state => {
      state.upcomingCalls.isExpanded = !state.upcomingCalls.isExpanded;
    },
    toggleAvgSpeedPanel: state => {
      state.avg.isExpanded = !state.avg.isExpanded;
    },
    toggleResponsePanel: state => {
      state.response.isExpanded = !state.response.isExpanded;
    },
    toggleParkedCallsPanel: state => {
      state.parked.isExpanded = !state.parked.isExpanded;
    },
    toggleActiveCallsPanel: state => {
      state.active.isExpanded = !state.active.isExpanded;
    },
  },
  extraReducers: {
    // get overview download
    [getOverviewData.pending]: state => {
      state.filters.isLoading = true;
    },
    [getOverviewData.fulfilled]: (state, action) => {
      state.filters.isLoading = false;

      exportToCSV(action.payload);
    },
    [getOverviewData.rejected]: (state, { payload, error }) => {
      state.filters.isLoading = false;
      state.filters.error = payload?.message ?? error.message;
    },
    // get overview lead download
    [getOverviewLeadData.pending]: state => {
      state.filtersLead.isLoading = true;
    },
    [getOverviewLeadData.fulfilled]: (state, action) => {
      state.filtersLead.isLoading = false;

      exportToCSV(action.payload);
    },
    [getOverviewLeadData.rejected]: (state, { payload, error }) => {
      state.filtersLead.isLoading = false;
      state.filtersLead.error = payload?.message ?? error.message;
    },
    // get performance
    [getPerformance.pending]: state => {
      state.performance.isLoading = true;
    },
    [getPerformance.fulfilled]: (state, action) => {
      state.performance.isLoading = false;
      state.performance = { ...state.performance, ...action.payload.performance };
    },
    [getPerformance.rejected]: (state, { payload, error }) => {
      state.performance.isLoading = false;
      state.performance.error = payload?.message ?? error.message;
    },
    // get sources
    [getSources.pending]: state => {
      state.sources.isLoading = true;
    },
    [getSources.fulfilled]: (state, action) => {
      state.sources.isLoading = false;
      state.sources.list = action.payload.sources;
    },
    [getSources.rejected]: (state, { payload, error }) => {
      state.sources.isLoading = false;
      state.sources.error = payload?.message ?? error.message;
    },
    // get results
    [getResults.pending]: state => {
      state.results.isLoading = true;
    },
    [getResults.fulfilled]: (state, action) => {
      state.results.isLoading = false;
      state.results = { ...state.results, ...action.payload.results };
    },
    [getResults.rejected]: (state, { payload, error }) => {
      state.results.isLoading = false;
      state.results.error = payload?.message ?? error.message;
    },
    // get call activities
    [getCallActivities.pending]: state => {
      state.callActivities.isLoading = true;
    },
    [getCallActivities.fulfilled]: (state, action) => {
      state.callActivities.isLoading = false;
      state.callActivities = { ...state.callActivities, ...action.payload.callActivities };
    },
    [getCallActivities.rejected]: (state, { payload, error }) => {
      state.callActivities.isLoading = false;
      state.callActivities.error = payload?.message ?? error.message;
    },
    // get upcoming calls
    [getUpcomingCalls.pending]: state => {
      state.upcomingCalls.isLoading = true;
    },
    [getUpcomingCalls.fulfilled]: (state, action) => {
      state.upcomingCalls.isLoading = false;
      state.upcomingCalls = { ...state.upcomingCalls, ...action.payload.upcomingCalls };
    },
    [getUpcomingCalls.rejected]: (state, { payload, error }) => {
      state.upcomingCalls.isLoading = false;
      state.upcomingCalls.error = payload?.message ?? error.message;
    },
    // get avg speed
    [getAvgSpeed.pending]: state => {
      state.avg.isLoading = true;
    },
    [getAvgSpeed.fulfilled]: (state, action) => {
      state.avg.isLoading = false;
      state.avg = { ...state.avg, ...action.payload.avg };
    },
    [getAvgSpeed.rejected]: (state, { payload, error }) => {
      state.avg.isLoading = false;
      state.avg.error = payload?.message ?? error.message;
    },
    // get response
    [getResponse.pending]: state => {
      state.response.isLoading = true;
    },
    [getResponse.fulfilled]: (state, action) => {
      state.response.isLoading = false;
      state.response = { ...state.response, ...action.payload.response };
    },
    [getResponse.rejected]: (state, { payload, error }) => {
      state.response.isLoading = false;
      state.response.error = payload?.message ?? error.message;
    },
    // get parked calls
    [getParkedCalls.pending]: state => {
      state.parked.isLoading = true;
    },
    [getParkedCalls.fulfilled]: (state, action) => {
      state.parked.isLoading = false;
      state.parked.loaded = true;
      state.parked = { ...state.parked, ...action.payload.parked };
    },
    [getParkedCalls.rejected]: (state, { payload, error }) => {
      state.parked.isLoading = false;
      state.parked.error = payload?.message ?? error.message;
    },
    // get parked calls download data
    [getParkedCallsData.pending]: state => {
      state.loading = true;
    },
    [getParkedCallsData.fulfilled]: state => {
      state.loading = false;
    },
    [getParkedCallsData.rejected]: (state, { payload, error }) => {
      state.loading = false;
      state.error = payload?.message ?? error.message;
    },
    // remove parked call
    [removeParkedCall.pending]: state => {
      state.parked.isLoading = true;
    },
    [removeParkedCall.fulfilled]: (state) => {
      state.parked.isLoading = false;
    },
    [removeParkedCall.rejected]: (state, { payload, error }) => {
      state.parked.isLoading = false;
      state.parked.error = payload?.message ?? error.message;
    },
    // get active calls
    [getActiveCalls.pending]: state => {
      state.active.isLoading = true;
    },
    [getActiveCalls.fulfilled]: (state, action) => {
      state.active.isLoading = false;
      state.active.loaded = true;
      state.active.list = action.payload.active;
    },
    [getActiveCalls.rejected]: (state, { payload, error }) => {
      state.active.isLoading = false;
      state.active.error = payload?.message ?? error.message;
    },
  },
});

export default overviewSlice.reducer;

export const actions = {
  ...overviewSlice.actions,
  getOverviewData,
  getOverviewLeadData,
  getPerformance,
  getSources,
  getResults,
  getCallActivities,
  getUpcomingCalls,
  getAvgSpeed,
  getResponse,
  getParkedCalls,
  getParkedCallsData,
  removeParkedCall,
  getActiveCalls,
};
