/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'blackoutDays',
  initialState: {
    days: [
      { start: new Date(), end: new Date(), recurring: true },
      { start: new Date(), end: new Date(), recurring: true },
      { start: new Date(), end: new Date(), recurring: true },
    ],
  },
  reducers: {
    setDay: (state, action) => {
      state.days[action.payload.id] = action.payload.day;
    },
    setNewDay: (state, action) => {
      state.days.push(action.payload);
    },
    deleteDay: (state, action) => {
      delete state.days[action.payload];
    },
  },
});

export const { setDay, setNewDay, deleteDay } = Slice.actions;
export default Slice.reducer;
