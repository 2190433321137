import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { makeStyles, AppBar, Toolbar, IconButton, Divider } from '@material-ui/core';

import LogoutIcon from 'src/assets/icons/logout.svg';

import { removeAccessToken, removeCurrentUser } from 'src/api/_common';

import checkRole from 'src/helpers/aclHelper';

import { HeaderGroupsPicker } from './components/groupsList/HeaderGroupsPicker';
import { HeaderPauseCallsSwitcher } from './components/pauseCalls/HeaderPauseCallsSwitcher';
import UserInfo from './userInfo';

const useStyles = makeStyles(theme => ({
  appBar: ({ drawerWidth }) => ({
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  }),
  toolbar: {
    marginLeft: 'auto',
    padding: theme.spacing(4, 3),
    // width: 460,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  logOutBtn: {
    borderRadius: '4px',
  },
  logOut: {
    whiteSpace: 'nowrap',
    paddingRight: 8,
    fontSize: 14,
    fontFamily: 'Lato',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  userInfo: {
    padding: '0 10px',
  }
}));

const handleLogoutClick = () => {
  window.dataLayer.push({
    "event": "Logout",
    // "context": {
    //   "staging": process.env.DEVELOPMENT_LOGOUT,
    // },
  });

  removeAccessToken();
  removeCurrentUser();
  window.dataLayer = [];

  if (process.env.DEVELOPMENT_LOGOUT == 'false') {
    window.location.replace('https://leverly.com/');
    return;
  }

  navigate('/', { replace: true });
}

const Info = () => {
  const { pathname } = useLocation();

  if (pathname.match('integrations') ||
    pathname.match('routing-rules') ||
    pathname.match('users') ||
    pathname.match('parked-calls') ||
    pathname.match('advance-settings')) {
    return <UserInfo />;
  }

  return <HeaderGroupsPicker />;
};

export default function Header({ drawerWidth }) {
  const classes = useStyles({ drawerWidth });

  return (
    <AppBar position="relative" color="transparent" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        {/* <IconButton aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={2} color="primary" overlap="rectangular">
            <NotificationsOutlinedIcon color="secondary" />
          </Badge>
        </IconButton> */}

        {checkRole(['admin', 'manager']) &&
          <HeaderPauseCallsSwitcher />
        }

        <Divider orientation="vertical" flexItem />

        <Info />

        <Divider orientation="vertical" flexItem />

        <IconButton aria-label="Log out" className={classes.logOutBtn} color="inherit" onClick={handleLogoutClick}>
          <span role="button" className={classes.logOut}>Log out</span>
          <LogoutIcon color="secondary" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
